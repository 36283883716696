<template>
	<component :is="link ? 'a' : 'button'" class="c-button" :href="link" target="_blank" @click="click">
		<span class="content">
			<slot></slot>
		</span>
		<span class="arrow"><img src="https://s3.us-west-1.amazonaws.com/agi.love/img/arrow-right.png" alt=""></span>
	</component>
</template>

<script>
	export default {
		props: {
			link: {
				type: String,
				default: ""
			}
		},
		methods: {
    click() {
      this.$emit("click");
    },
  },
		
	}
</script>

<style lang="scss" scoped>
	.c-button {
		height: 50px;
		border-radius: 30px;
		width: 192px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 3px 0 23px;
		cursor: pointer;
		outline: none;
		border: none;
		transition: all 0.6s cubic-bezier(.26, 1.04, .54, 1);
		position: relative;
		background: linear-gradient(to right, #0016dd, #4c7cf9);
		overflow: hidden;


		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #4c7cf9, #2d41fa);
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			transition: opacity 3s cubic-bezier(.26, 1.04, .54, 1), transform 4s cubic-bezier(.26, 1.04, .54, 1), -webkit-transform 4s cubic-bezier(.26, 1.04, .54, 1);
			opacity: 0;
			border-radius: 30px;
			overflow: hidden;
			// transform: translateX(20%);
		}

		&:hover {
			width: 246px;

			@screen md {
				width: 192px;
			}

			&::after {
				opacity: 1;
				z-index: 1;
			}
		}

		.content {
			position: relative;
			z-index: 2;
		}

		.arrow {
			height: 44px;
			width: 44px;
			min-width: 44px;
			display: block;
			border-radius: 22px;
			background-color: #000000;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 2;
		}
	}
</style>