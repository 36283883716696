<template>
  <a-drawer placement="top" :closable="false" :visible="visible" @close="onClose" :zIndex="999">
    <div class="drawer-menu-list">
      <div class="menu-box">
        <div class="menu-w">
          <div class="menu-item" v-for="(item, index) in menu" :key="item.key">
            <div class="menu-item-box" v-if="activeMenu === index">
              <h1>{{ item.name }}</h1>
              <div class="menu-list-item">
                <div class="second-menu" v-for="(val, index) in item.children" :key="index">
                  <div class="menu-li">
                    <p class="second-name" v-show="val.secondName">{{ val.secondName }}</p>
                    <div class="menu-li-box" v-for="(liItem, index) in val.children" :key="liItem.key" :class="{ disabled: liItem.disabled }" @click="toPage(liItem)">
                      <span>{{ liItem.name }}</span>
                      <div v-if="liItem.new" class="dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    activeMenu: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    menu() {
      return [
        {
          name: '市场',
          key: 'market',
          children: [
            {
              secondName: '',
              children: [
                { name: '提供算力', key: 'share', route: 'Supply' },
                { name: '接入算力', key: 'rental', route: 'Access' },
              ]
            }
          ]
        },
        {
          name: '生态系统',
          key: 'ecosystem',
          children: [
            {
              secondName: '',
              children: [
                // {
                //   name: '基金会拨款',
                //   key: 'grants',
                //   href: 'https://bpflac.notion.site/BP-FLAC-Grant-Incubation-Program-428225c85a524de5b8ef7bb55cf766fc'
                // },
                { name: '去中心化应用', key: 'dapp', disabled: true },
                { name: '开发者', key: 'developer', disabled: true },
              ]
            }
          ]
        },
        {
          name: '网络',
          key: 'network',
          children: [
            {
              secondName: 'FORGENET',
              children: [
                { name: 'Forgenet Explorer', key: 'test', href: 'https://explorer.wodrpc.org/#/index' },
                { name: 'Forgenet Bridge', key: 'bridge', href: 'https://bridge.wodrpc.org/', new: true},
                { name: 'Bridge Campaign', key: 'campaign', route: 'BridgeCampaign', new: true},
                { name: 'Forgenet Faucet', key: 'faucet', route: 'Faucet' },
              ]
            },
            {
              secondName: 'EON',
              children: [
                { name: 'Eon 网站', key: 'website', href: 'https://eonchain.xyz' },
                {
                  name: '交易 Eon',
                  key: 'swap',
                  href: 'https://wodswap.io/#/swap?use=v2&inputCurrency=0x31cE96Ae990c374A567B3DF38ee4c1D27e630F8C'
                },
                // { name: 'Claim Eon', key: 'claim', route: 'Airdrop' },
              ]
            }
          ]
        },
        {
          name: '治理',
          key: 'governance',
          children: [
            {
              secondName: '',
              children: [
                { name: '文档', key: 'docs', href: 'https://wmkjcds-organization.gitbook.io/agi/' },
                { name: '委托', key: 'delegate', href: 'https://explorer.wodrpc.org/#/stake' },
                { name: '论坛', key: 'forum', disabled: true },
                { name: '投票', key: 'vote', disabled: true },
              ]
            }
          ]
        },
        {
          name: '社交平台',
          key: 'social',
          children: [
            {
              secondName: '',
              children: [
                { name: '推特', key: 'twitter', href: 'https://twitter.com/wod_global' },
                { name: 'Discord', key: 'discord', href: 'https://discord.gg/CscJeyvybG' },
                { name: 'Medium', key: 'medium', href: 'https://medium.com/@wod_global' },
                { name: 'Telegram', key: 'telegram', href: 'https://t.me/Wod_Global' },
                { name: 'Youtube', key: 'youtube', href: 'https://www.youtube.com/@BP-FLAC_2023' },
              ]
            }
          ]
        },
        {
          name: 'Journey',
          key: 'journey',
          children: [
            {
              secondName: '',
              children: [
                { name: 'Medal Wall', key: 'medalwall', route: 'MedalWall', new: true },
                { name: 'NFT', key: 'nft', route: 'NFT', new: true },
              ]
            }
          ]
        },
      ]
    }
  },
  watch: {
    show(newVal, oldVal) {
      this.visible = this.$props.show
    }
  },
  created() {
    this.visible = this.$props.show
  },
  mounted() {

  },
  methods: {
    onClose() {
      this.$emit('onClose', false)
      this.visible = false;
    },
    toPage(item) {
      if (item.disabled) return
      if (!item.route && !item.href) return
      if (item.href) {
        window.open(item.href)
      } else {
        this.$router.push({ name: item.route })
      }
    }
  },
}
</script>
<style lang="scss">
.head-menu {
  width: 100%;
  height: 100%;
}

.ant-drawer-content-wrapper {
  height: auto !important;
}

.ant-drawer-content {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.60);

  .ant-drawer-body {
    padding: 0;
  }

  .drawer-menu-list {
    padding-top: 80px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(28px);

    .menu-box {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 32px 0;
      .menu-w {
        min-width: 520px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .menu-item {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .menu-item-box {
          display: flex;
          flex-direction: column;

          h1 {
            color: rgba(255, 255, 255, 0.80);
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            /* 100% */
            letter-spacing: 0.24px;
            margin: 0;
            margin-bottom: 32px;
          }

          .menu-list-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 60px;
          }

          .second-menu {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .second-name {
              color: rgba(255, 255, 255, 0.40);
              font-size: 12px;
              font-weight: 600;
              line-height: 12px;
              margin: 0;
              margin-bottom: 24px;
            }

            .menu-li {
              display: flex;
              flex-direction: column;
              gap: 12px;

              span {
                color: rgba(255, 255, 255, 0.60);
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;

                &:hover {
                  background: linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              .menu-li-box {
                display: flex;
                align-items: flex-start;
                gap: 3px;
                .dot {
                  width: 6px;
                  height: 6px;
                  background-color: #4CE4F9;
                  border-radius: 50%;
                }
              }

              .disabled {
                opacity: .5;
                cursor: no-drop;

                &:hover {
                  background: rgba(255, 255, 255, 0.60);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>