<template>
  <div class="home">
    <Header></Header>
    <div class="banner-content-box">
      <Banner></Banner>
    </div>
    
    <Introduce></Introduce>
    <Productivity></Productivity>
    <Dapp></Dapp>
    <!-- <Solution></Solution> -->
    <!-- <Partnership></Partnership> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/home/Banner.vue'
import Introduce from '@/components/home/Introduce.vue';
import Dapp from '@/components/home/Dapp.vue';
import Solution from '@/components/home/Solution.vue';
import Partnership from '@/components/home/Partnership.vue';
import Productivity from '../components/home/Productivity.vue';
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Introduce,
    Solution,
    Productivity,
    Partnership,
    Header,
    Footer,
    Banner,
    Dapp
  },
  mounted(){
    console.log('this.$route.query',this.$route.query)
    if(this.$route.query.address){

    }
    // this.$prompt({
    //   status:2
    // })
  }
}
</script>

<style lang="scss" scoped>

.home {
  position: relative;
  background-color: #000000;
  color: #ffffff;
  .banner-content-box {
    min-height: 100vh;
    @screen md {
      min-height: 100vh;
    }
  }
  .static-bg-1 {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 0;
  }
}

</style>
