import request from '@/utils/request';
import store from '@/store/index.js';

// 获取地址签名
export const GetKey = async params => {
    try {
        const res = await request({
            url: '/Users/GetKey',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};

// 检查地址是否已经注册
export const CheckAddressRegisted = async params => {
    try {
        const res = await request({
            url: '/Users/CheckAddressRegisted',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};

// 注册
export const Register_address = async params => {
    try {
        const res = await request({
            url: '/Users/Register_address',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};

// 登录
export const UserLoginAddress = async params => {
    try {
        const res = await request({
            url: '/Users/UserLoginAddress',
            method: 'post',
            params,
        });
        if (res.success) {
            store.commit('SET_TOKEN', res.data);
            await Info();
            await Balance()
        }
        return res;
    } catch (err) {
        return false;
    }
};

// 用户余额
export const Balance = async () => {
    try {
        const res = await request({
            url: '/Users/Balance',
            method: 'post',
        });
        if (res.data.length > 0) {
            let a = {};
            res.data.forEach(element => {
                element.balance = element.balance - element.lockbalance;
                a[element.coinid] = element;
            });
            store.commit('SET_BALANCE', a);
        }
        return res;
    } catch (err) {
        return false;
    }
};

// 用户信息
export const Info = async params => {
    try {
        const res = await request({
            url: '/Users/Info',
            method: 'post',
            params,
        });
        if (res.success) store.commit('SET_INFO', res.data);
        return res;
    } catch (err) {
        return false;
    }
};

// 用户全网信息
export const getTeamAllPower = async () => {
    try {
        const res = await request({
            url: '/Users/getTeamAllPower',
            method: 'post',
        });
        return res;
    } catch (err) {
        return false;
    }
};

// 用户总业绩
export const mytotal = async () => {
    try {
        const res = await request({
            url: '/Users/mytotal',
            method: 'post',
        });
        return res;
    } catch (err) {
        return false;
    }
};

// 用户团队
export const teamtotal = async params => {
    try {
        const res = await request({
            url: '/Users/teamtotal',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
