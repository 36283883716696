<template>
  <div class="banner-bridge">
    <div class="bridge-campaign-content">
      <div class="content-left">
        <h1><span>AGI</span> Bridge Campaign is Live!</h1>
        <span class="second">Jan.29th, 2024 - Main Net Launch</span>
        <span class="slogon">Bridge Your Assets, Earn the Medals, Secure Future Airdrops!</span>
        <div class="btn-box" @click="toPage">
          <Button :class="$lang">Get Early Access</Button>
        </div>
      </div>
      <div class="content-right">
        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/model-bridge-campaign.png" alt="">
      </div>
    </div>
    <ModalBridge :visible="showModal" @closeModal="closeModal"></ModalBridge>
  </div>
</template>
<script>
import Button from '@/components/Button.vue';
import ModalBridge from './modal/ModalBridge.vue';
export default {
  components: {
    Button,
    ModalBridge
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    toPage() {
      this.$router.push({name: 'BridgeCampaign'})
    }
  }
}
</script>
<style lang="scss">
.banner-bridge {
  width: 100%;
  height: 1080px;
  color: #fff;
  padding: 0 75px;
  display: flex;

  @screen md {
    padding: 0 15px;
  }

  .bridge-campaign-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 29px;

    @screen md {
      flex-direction: column-reverse;
      gap: 20px;
      padding-top: 45px;
    }

    .content-left {
      max-width: 756px;
      display: flex;
      flex-direction: column;

      @screen md {
        margin-bottom: 30px;
        text-align: center;
      }

      h1 {
        color: #FFF;
        font-size: 86px;
        font-weight: 800;
        line-height: 92px;
        letter-spacing: 0.86px;
        margin: 0;
        margin-bottom: 35px;

        @screen md {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 0.32px;
          text-align: center;
        }

        span {
          background-image: linear-gradient(to right, #33C4F2, #1F35FD);
          background-clip: text;
          color: transparent;
          display: inline-block;
        }
      }

      .second {
        display: inline-block;
        color: #FFF;
        font-size: 24px;
        font-weight: 510;
        margin-bottom: 57px;

        @screen md {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }

      .slogon {
        color: rgba(255, 255, 255, 0.60);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        display: inline-block;
        margin-bottom: 48px;

        @screen md {
          font-size: 12px;
          margin-bottom: 20px;
        }
      }

      .btn-box {
        width: 272px;
        display: inline-block;
        margin-bottom: 35px;

        @screen md {
          margin: auto;
          margin-bottom: 20px;
          width: 188px;
        }

        button {
          width: 100%;
        }
      }

      .to-web {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.60);
        font-size: 16px;
        font-weight: 300;
        line-height: 27.576px;
        padding-left: 33px;

        @screen md {
          padding-left: 0;
          font-size: 12px;
        }

        span {
          padding-bottom: 10px;
          display: inline-block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.60);

          @screen md {
            padding-bottom: 4px;
          }
        }
      }
    }

    .content-right {
      width: 770px;
      min-width: 770px;
      display: flex;
      align-items: center;
      justify-content: center;

      @screen md {
        width: 250px;
        min-width: 250px;
      }

      img {
        width: 100%;
      }
    }
  }

  @media (min-width: 965px) and (max-width: 1440px) {
    height: 650px;

    .bridge-campaign-content {
      .content-left {
        max-width: 756px;
        display: flex;
        flex-direction: column;


        h1 {
          color: #FFF;
          font-size: 56px;
          font-weight: 800;
          line-height: 70px;
          letter-spacing: 0.86px;
          margin: 0;
          margin-bottom: 25px;

        }

        .second {
          display: inline-block;
          color: #FFF;
          font-size: 20px;
          font-weight: 510;
          margin-bottom: 18px;

        }

        .slogon {
          color: rgba(255, 255, 255, 0.60);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          display: inline-block;
          margin-bottom: 38px;
        }

      }

      .content-right {
        width: 550px;
        min-width: 550px;
      }
    }
  }

  @media screen and (max-width: 965px) {
    height: 630px;
  }

}
</style>