<template>
    <div class="c-footer md:px-12">
        <!-- <img class="logo" src="https://s3.us-west-1.amazonaws.com/agi.love/img/logo.png" alt=""> -->
        <!-- <img class="logo" src="../assets/img/logo.png" alt=""> -->
        <!-- <div class="flex justify-between mt-12 md:block">
      <div class="desc">使用我们强大的BP-FLAC在创纪录的时间内创建精美的、专业品质的AI-Dapp</div>
      <div class="channels md:mt-40">
        <a href="https://t.me/Wod_Global" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/img/telegram.svg" alt=""></a>
        <a href="https://discord.gg/CscJeyvybG" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/img/discord.svg" alt=""></a>
        <a href="https://twitter.com/wod_global" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/img/twitter.svg" alt=""></a>
        <a href="https://medium.com/@wod_global" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/img/medium.svg" alt=""></a>
      </div>
    </div> -->

        <div class="copy-right md:mt-22">
            <div class="name">Copyright © 2024 AJ Labs</div>
            <div class="nav md:hidden">
                <a class="menu-item" v-for="(item, index) in menuList" href="/#">{{ item }}</a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            count: state => state.count,
            lang: state => state.lang,
        }),
        menuList() {
            return this.lang == 'en' ? ['Packages', 'Community', 'Invite Friends'] : ['套餐', '我的社区', '邀请好友'];
        },
    },
};
</script>
<style lang="scss" scoped>
.c-footer {
    max-width: 1920px;
    margin: 80px auto 0 auto;
    padding: 0 75px;
    position: relative;
    z-index: 9;

    @media screen and (min-width: 2070px) {
        padding: 0;
    }
    .logo {
        height: 36px;
        @screen md {
            height: 26px;
        }
    }

    .desc {
        font-size: 14px;
        line-height: 22px;
        max-width: 365px;
        color: rgba(255, 255, 255, 0.6);
    }

    .channels {
        display: flex;
        align-items: center;

        img {
            margin-left: 37px;

            @screen md {
                margin-left: 0;
                margin-right: 37px;
            }
        }
    }

    .copy-right {
        height: 56px;
        border-top: 1px solid rgba(58, 61, 66, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 48px;

        .name {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
        }

        .nav {
            .menu-item {
                font-size: 16px;
                margin-left: 53px;
                text-decoration: none;
                color: rgba(255, 255, 255, 0.6);
                position: relative;
                line-height: 24px;

                &.active,
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
}
</style>
