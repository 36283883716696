import router from "../../router";
import store from '../../store'
const web3 = window.web3 ? new Web3(window.ethereum) : '';
import { setAddress } from '@/utils/auth.js';
import mdialog from '@/mdialog'
import { message } from 'ant-design-vue';
const Erc20Token = require("./abi/Erc20Token.json");
import { toWei, fromWei,sendAsync,inquire} from "./tool.js";


/**
 * 获取并保存地址
 * @returns
 */
export async function getAndsetAddress() {
  if (!window.ethereum) return setAddress("");
  if (!window.web3) return setAddress("");
  if (!window.ethereum.isConnected()) setAddress("");
  let accounts = await web3.eth.getAccounts();
  console.log("getAndsetAddress[accounts]", accounts);
  let myAccount = accounts[0];
  return setAddress(myAccount);
}

export async function getSign(dataToSign = "", address = store.state.address) {
	console.log("dataToSign==>", dataToSign);
	try {
		let signedMsg = await web3.eth.personal.sign(dataToSign, address);
		return signedMsg;
	} catch (e) {
		console.log(`getSign2数据进行签名-err`, e);
		return false;
	}
}

export async function addEthereumChainFn(chainId) {
	console.log('chainId===>', chainId)
	let res = await window.ethereum.request({
		method: 'wallet_addEthereumChain', // Metamask的api名称
		params: [
			{
				chainId: "0xc05a6",
				chainName: "Wod Forgenet",
				nativeCurrency: {
					name: 'WOD',
					symbol: 'WOD',
					decimals: 18
				},
				rpcUrls: ['https://hk-node2.wodrpc.org'], // 'https://hk-node1.wodrpc.org'
				// blockExplorerUrls: [],
				// iconUrls: []
			}
		]
	})
	console.log('res[wallet_addEthereumChain]', res)
}
//检查环境
export function checkEnv() {
	if (!window.web3) {
		return {
			code: false,
			msg: 'no Web3'
		}
	}
	if (!ethereum) {
		return {
			code: false,
			msg: 'no ethereum'
		}
	}
	return {
		code: true,
	}
}


/**
 * 登录
 * @returns
 */
export async function enable() {
	try {
		const { code } = checkEnv()
		const chainId = await getChainId()
		
		// if (chainId != 787878) {
		// 	return  message.error('Change network to forgenet');
		// }
		if (!code) {
			mdialog.install();
			return {code: false};
		}

		let account = await ethereum.request({
			method: 'eth_requestAccounts'
		});
		if (account) {
			let accounts = await web3.eth.getAccounts();
			let myAccount = accounts[0];

			setAddress(myAccount)
			console.log('myAccount===>', myAccount)
			store.commit('SET_ADDRESS', myAccount);
			return {
				code: true
			};
		}
	} catch (e) {
		console.log('登录err===>', e)
		if (e === "User rejected provider access") {
			//用户不想登录!  退出
			return {
				code: false
			}
		} else {
			// code:-32002    未登录
			return {
				code: false
			}
		}
	}
}

//ethereum 事件监听
export function registerListener() {
	if (!ethereum) return;
	// 链ID
	ethereum.on("chainChanged", newChainId => {
		console.log("chainChanged===>", newChainId, parseInt(newChainId));
		location.reload('/');
	});
	// 监听连接
	ethereum.on("connect", connectInfo => {
		console.log("connect===>", connectInfo, connectInfo.chainId, parseInt(connectInfo.chainId));
		// if (parseInt(connectInfo.chainId) != '787878') {
		// 	store.commit('CLEAR', '');
		// 	store.commit('SET_ADDRESS', '');
		// }
	});
	// 地址改变
	ethereum.on("accountsChanged", accounts => {
		let newAddress = "";
		if (accounts.length > 0) {
			newAddress = accounts[0];
		}
		setAddress(newAddress)
		console.log('myAccount===>', newAddress)
		store.commit('SET_ADDRESS', newAddress);
		store.commit('SET_TOKEN', '')
		window.location.reload();
		
	});
}

//查询网络
export async function eth_chainId({ chainIdArr }) {
	try {
		let chainId = await ethereum.request({
			method: "eth_chainId"
		});
		console.log('chainIdArr===>', chainIdArr)
		console.log(`查询网络eth_chainId===>`, chainId, parseInt(chainId))
		if (chainIdArr.includes(parseInt(chainId))) {
			return {
				code: true,
				msg: '',
				chainId: parseInt(chainId),
			}
		}
		return {
			code: false,
			msg: 'network',
			chainId: parseInt(chainId),
		}
	} catch (e) {
		return {
			code: false,
			msg: 'error'
		}
	}
}

//查询网络
export async function getChainId() {
	try {
		let chainId = await ethereum.request({
			method: "eth_chainId"
		});
		console.log(`查询网络eth_chainId===>`, chainId, parseInt(chainId))
		return parseInt(chainId)
	} catch (e) {
		console.log(e)
		return {
			code: false,
			msg: 'error'
		}
	}
}


/**
 * 获取区块时间
 * @returns  时间搓（S）
 */
export async function getBlockTimestamp() {
	try {
		let num = await web3.eth.getBlockNumber();
		let res = await web3.eth.getBlock(num);
		console.log('getBlockTimestamp===>', res)
		return res.timestamp
	} catch (error) {
		console.log('获取区块时间--->', error)
	}

}

/**
 * 获取交易详情
 */
export async function getTranDetail(transactionHash) {
	try {
		let transaction = await web3.eth.getTransaction(transactionHash);
		if (!transaction) {
			transaction = await web3.eth.getTransaction(transactionHash);
		}
		let transactionReceipt = await web3.eth.getTransactionReceipt(transactionHash);
		if (!transaction) return;
		transaction = Object.assign(transaction, transactionReceipt);
		console.log('获取交易详情===>', transaction)
		return transaction;
		// if (Object.keys(transaction["logs"]).length == 0) {
		//   console.log('暂无logs');
		//   return {};
		// }
		// const { topics, data } = transaction["logs"].pop();
	} catch (error) {
		console.log('获取交易详情--->', error);
	}
}


export async function disconnect() {
	try {
		if (!ethereum) return false;
		web3.currentProvider.disconnect()

	} catch (e) {
		console.log('登录err===>', e)
		if (e === "User rejected provider access") {
			//用户不想登录!  退出
			return {
				code: false
			}
		} else {
			// code:-32002    未登录
			return {
				code: false
			}
		}
	}
}

export const connectWallet = async () => {
	if (window.ethereum) { //check if Metamask is installed
		try {
			const address = await window.ethereum.enable(); //connect Metamask
			const obj = {
				connectedStatus: true,
				status: "",
				address: address
			}
			return obj;

		} catch (error) {
			return {
				connectedStatus: false,
				status: "🦊 Connect to Metamask using the button on the top right."
			}
		}

	} else {
		return {
			connectedStatus: false,
			status: "🦊 You must install Metamask into your browser: https://metamask.io/download.html"
		}
	}
};




/**
 * @method 获取TOKEN余额
 * @param {String}  tokenAddress   代币地址
 * @param {object}  option         可选参数
 * @param {Number}  decimals       小数位数 --默认0 即 返回大数类型
 * @param {String}  searchAddress  查询地址  -- 默认当前地址
 * @returns         token余额
 */
export function balanceOf(tokenAddress, { decimals = 0, searchAddress = store.state.address } = {}) {
  return new Promise((resolve, reject) => {
    const contract = new web3.eth.Contract(Erc20Token, tokenAddress);
    contract.methods
      .balanceOf(searchAddress)
      .call((err, result) => {
        console.log(`地址-[${searchAddress}]--代币-[${tokenAddress}]的余额===>`, result)
        if (err) {
          resolve(0);
        }
        if (result) {
          resolve(fromWei(result, decimals));
        }
      });
  });
}


/**
 * @method 查询总量
 * @param {address} address  查询代币地址
 * @param {Object} option    可选参数
 * @param {unit} decimals    小数位数---默认0 即返回大数类型
 * @returns   代币总量
 */
export function totalSupply(address, { decimals = 0 } = {}) {
  return new Promise((resolve, reject) => {
    const contract = new web3.eth.Contract(Erc20Token, address);
    contract.methods
      .totalSupply()
      .call((err, result) => {
		  
		   console.log(`地址-[${address}]--===>`, result)
        if (err) {
          resolve(false);
        }
        if (result) {
          resolve(fromWei(result, decimals));
        }
      });
  });
}

/**
 * @method 检查授权
 * @param {String}  token           授权币种
 * @param {String}  contractAddress 合约地址 
 * @param {Number}  num             数量
 * @returns 授权数量
 */
export async function allowance2(token, contractAddress) {
  // store.commit('common/ADD_LOADING');
  try {
    const contracts = new web3.eth.Contract(Erc20Token, token);
    let result = await contracts.methods.allowance(store.state.address, contractAddress).call();
    // store.commit('common/CLOSE_LOADING');
    console.log(`allowance[${token}--to--${contractAddress}]===>`, result);
   return result
  } catch (e) {
    // store.commit('common/CLOSE_LOADING');
    console.log(`allowance[${token}]-->`, e);
    return 0;
  }
}


/**
 * @method 检查授权
 * @param {String}  token           授权币种
 * @param {String}  contractAddress 合约地址 
 * @param {Number}  num             数量
 * @returns 授权数量
 */
export async function allowance(token, contractAddress, num = 100000000) {
  // store.commit('common/ADD_LOADING');
  try {
    const contracts = new web3.eth.Contract(Erc20Token, token);
    let result = await contracts.methods.allowance(store.state.address, contractAddress).call();
    // store.commit('common/CLOSE_LOADING');
    console.log(`allowance[${token}--to--${contractAddress}]===>`, result);
    if (result == 0) { // 没有授权
      return false;
    } else if (result && BigInt(result) >= BigInt(num)) {
      return true;
    } else {
      Toast(lang.sqslbz)
      return false;
    }
  } catch (e) {
    // store.commit('common/CLOSE_LOADING');
    console.log(`allowance[${token}]-->`, e);
    return false;
  }
}

/**
 * @method 检查授权 -- 多币种
 * @param {Object} hashToken
 * {
      tokenAddress:       // 授权币种,
      tokenName: 'name',  // 名称
      contractAddress:    //  合约地址
      num: 0,             数量 默认 100000000
      type:'NFT'          NFT NFT授权  token 代币授权
 }
 **/
export async function mulitAllowance(hashToken) {
  console.log("mulitAllowance[hashToken]===>", hashToken);
  //没有地址则退出
  if (hashToken.length <= 0) {
    //TODO   →   Toast
    return false;
  }
  let promises = hashToken.map(async (item) => {
    console.log("mulitAllowance[item]====>", item);
    let res;
    if (item.type == "NFT") {
      res = await isApprovedForAll(item.tokenAddress, item.contractAddress);
    } else {
      item.num = item.num ? item.num : "10000000000000000000000";
      res = await allowance(item.tokenAddress, item.contractAddress, item.num);
    }
    item.state = res;
  });
  //当所有请求执行完毕
  let state = await Promise.all(promises);
  //遍历数组对象是否都授权
  let alowanceAll = !hashToken.some((item) => {
    return item.state == false;
  });
  return {
    alowanceAll,
    hashToken,
  };
}

/**
 * 币种授权
 * @param {String} tokenAddress   授权币种
 * @param {String} ToAddress      合约地址
 * @param {Number} num            授权数量
 * @returns
 */
export async function approve(tokenAddress, ToAddress, num = '1000000000000000000000000000000') {
  let contracts = new web3.eth.Contract(Erc20Token, tokenAddress);
  let params = [{
    from: store.state.address,
    to: tokenAddress,
    value: "0x0",
    data: contracts.methods.approve(ToAddress, web3.utils.toBN(num)).encodeABI(),
  }];
  let {
    code,
    result
  } = await sendAsync(params);
  console.log(`approve[${tokenAddress}]====>`, code, result)
  if (code) {
    return inquire(result)
  } else {
    return code
  }
}


/**
 * @method NFT授权检查
 * @param {Address} address
 * @param {Address} ToAddress
 */
export async function isApprovedForAll(address, ToAddress) {
  store.commit('common/ADD_LOADING');
  try {
    const contracts = new web3.eth.Contract(NFTToken, address);
    let result = await contracts.methods.isApprovedForAll(store.state.address, ToAddress).call();
    // store.commit('common/CLOSE_LOADING');
    console.log(`isApprovedForAll[${address}]===>`, result);
    return result
  } catch (e) {
    // store.commit('common/CLOSE_LOADING');
    console.log(`isApprovedForAll[${address}]--->`, e);
    return false;
  }
}


/**
 * @method NFT币种授权
 * @param {address} tokenAddress   授权币种
 * @param {address} ToAddress 合约地址
 * @returns
 */
export async function setApprovalForAll(tokenAddress, ToAddress) {
  const contracts = new web3.eth.Contract(NFTToken, tokenAddress);
  let params = [{
    from: store.state.address,
    to: tokenAddress,
    value: "0x0",
    data: contracts.methods.setApprovalForAll(ToAddress, true).encodeABI(), //
  }];
  let {
    code,
    result
  } = await sendAsync(params);
  console.log(`approve[${tokenAddress}]====>`, code, result)
  if (code) {
    return inquire(result)
  } else {
    return code
  }
}