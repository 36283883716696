import request from '@/utils/request';
import store from '@/store/index.js';

//获取基本配置
export const GetConfig = async () => {
    try {
        const res = await request({
            url: '/Ctrls/GetConfig',
            method: 'post',
        });
        if (res.length > 0) {
            let a = {};
            res.forEach(element => {
                a[element.idx] = element;
            });
            store.commit('SET_CONFIG', a);
        }
        return res;
    } catch (err) {
        return false;
    }
};

//获取基本配置
export const coinlist = async () => {
    try {
        const res = await request({
            url: '/Ctrls/coinlist',
            method: 'post',
        });
        if (res.length > 0) {
            let a = {};
            res.forEach(element => {
                a[element.coinid] = element;
            });
            store.commit('SET_COINCONFIG', a);
        }
        return res;
    } catch (err) {
        return false;
    }
};
//获取基本配置
export const GetEnum = async () => {
    try {
        const res = await request({
            url: '/Ctrls/GetEnum',
            method: 'post',
        });
        if (res.success) store.commit('SET_ENUM', res.data);
        return res;
    } catch (err) {
        return false;
    }
};
