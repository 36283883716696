import router from "../../router";
import store from '../../store'
import { Toast, Dialog } from "vant";

import { message } from 'ant-design-vue';

const web3 = window.web3 ? new Web3(window.ethereum) : '';

const doError = (e, option = {}) => {
	if (e instanceof Error) { // 通用类型
	} else if (e instanceof EvalError) { // 表示与全局函数eval()有关的错误；
	} else if (e instanceof RangeError) { // 表示数值变量或参数超出其有效范围；
	} else if (e instanceof ReferenceError) { // 表示无效引用。比如引用一个不存在的变量；
	} else if (e instanceof TypeError) { //表示值的类型非预期类型时发生的错误。比如当数据类型不正确时抛出错误；
	} else if (e instanceof SyntaxError) { // 表示eval()在解析代码的过程中发生的语法错误。
	} else if (e instanceof URIError) { // encodeURI() 函数产生的错误
	}
	console.error("%c%s",
		"color: red; background: yellow; font-size: 12px;",
		`${e.name || 'Error'}--->`, e);
	if (e.code && (e.code == 4001 || e.code == -32603)) {
		// Toast(lang.qxcz);
		message.error('Cancel Operation');
	} else {
		message.error(e.message);

	}
	return false
}

/**
 * @function 查询函数
 * @description 以太坊读函数
 * @param {object}  object
   @param {String}  name                函数名称 非必传
   @param {Array}   contractAbi         合约ABI
   @param {String}  contractAddress     合约地址
   @param {String}  fn                  合约方法
   @param {Array}   params              合约参数 数组 Arr
   @param {Boolean} loading             加载状态 非必传
 **/
export function queryTool2({ name = '', contractAbi, contractAddress, fn, params, loading = false } = {}) {
	return new Promise(async (resolve, reject) => {
		try {
			const { methods } = new web3.eth.Contract(contractAbi, contractAddress);
			const fun = params ? methods[fn](...params) : methods[fn]();
			fun.call((err, result) => {
				if (err) {
					console.log(`${fn}[${name}]--->`, err)
					console.log(`${fn}[${name}]--contractAddress,params===`, [contractAddress, ...
						params
					])
					resolve(false);
				} else {
					console.log(`${fn}[${name}]===>`, result)
					resolve(result);
				}
			})
		} catch (e) {
			if (loading) store.commit('common/SET_LOADING');
			console.log(`${fn}[${name}]--contractAddress,params===`, params ? [contractAddress, ...params] :
				[contractAddress])
			return doError(e)
		}
	})
}

/**
 * @function 查询函数
 * @description 以太坊读函数
 * @param {object}  object
   @param {String}  name                函数名称 非必传
   @param {Array}   contractAbi         合约ABI
   @param {String}  contractAddress     合约地址
   @param {String}  fn                  合约方法
   @param {Array}   params              合约参数 数组 Arr
   @param {Boolean} loading             加载状态 非必传
 **/
export async function queryTool({ name = '', contractAbi, contractAddress, fn, params, loading = false } = {}) {
	try {
		console.log(...params)
		const { methods } = new web3.eth.Contract(contractAbi, contractAddress);
		const call = params ? await methods[fn](...params).call() : await methods[fn]().call();
		// console.log(`${fn}[${name}]===>`, call)
		return call
	} catch (e) {
		console.log(e)
		console.log(`${fn}[${name}]--contractAddress,params===`, [contractAddress, ...params])
		// return doError(e)
	}
}

/**
 * @function 执行函数
 * @description 以太坊写函数
 * @param {object}  object {}
 * @param {Array}   contractAbi      合约ABI
 * @param {String}  contractAddress  合约地址
 * @param {String}  fn               合约方法
 * @param {Array}   params           合约参数 对象
 * @param {String}  fromAddress      发起地址 默认当前地址
 * @param {Boolean} returnReceipt    返回收据 默认 true
 */
export async function executeTool({
	contractAbi,
	contractAddress,
	fn,
	value = '0',
	params = [],
	fromAddress = store.state.address,
	returnReceipt = true
} = {}) {
	try {
		const { methods } = new web3.eth.Contract(contractAbi, contractAddress);
		let txObject = [{
			"from": fromAddress,
			"to": contractAddress,
			"value": web3.utils.toHex(value),
			"data": params && params.length ? methods[fn](...params).encodeABI() : methods[fn]().encodeABI()
		}];
		let {
			code,
			result
		} = await sendAsync(txObject);
		if (code) {
			if (!returnReceipt) return result; // 直接返回HASH
			return inquire(result) // 返回收据
		} else {
			return code
		}
	} catch (e) {
		console.log(`${fn}--contractAddress,params===`, [contractAddress, ...params])
		return doError(e)
	}
}

/**
 * @function 执行函数
 * @description 以太坊写函数
 * @param {object}  object
 * @param {Array}   contractAbi      合约ABI
 * @param {String}  contractAddress  合约地址
 * @param {String}  fn               合约方法
 * @param {Array}   params           合约参数 对象
 * @param {String}  fromAddress      发起地址 默认当前地址
 * @param {Boolean} returnReceipt    返回收据 默认 true
 */
export async function executeTool2({
	contractAbi,
	contractAddress,
	fn,
	params = [],
	fromAddress = store.state.address,
	returnReceipt = true
} = {}) {
	try {
		const { methods } = new web3.eth.Contract(contractAbi, contractAddress);
		const fun = params && params.length ? methods[fn](...params) : methods[fn]();
		let receipt;
		await fun.send({ "from": fromAddress, "value": "0x0", }).on('transactionHash', (transactionHash) => {
			console.log('transactionHash===>', transactionHash);
			if (!returnReceipt) return transactionHash; // 直接返回HASH
			receipt = inquire(transactionHash)
		});
		return receipt;
	} catch (e) {
		console.log(`${fn}--contractAddress,params===`, [contractAddress, ...params])
		return doError(e)
	}
}

/**
 * @function 执行函数
 * @description 以太坊写函数
 * @param {object}  object
 * @param {Array}   contractAbi      合约ABI
 * @param {String}  contractAddress  合约地址
 * @param {String}  fn               合约方法
 * @param {Array}   params           合约参数 对象
 * @param {String}  fromAddress      发起地址 默认当前地址
 * @param {Boolean} returnReceipt    返回收据 默认 true
 */
export async function executeTool3({
	contractAbi,
	contractAddress,
	fn,
	params,
	fromAddress = store.state.address,
	returnReceipt = true
} = {}) {
	
	try {
		const { methods } = new web3.eth.Contract(contractAbi, contractAddress);
		let txObject = [{
			"from": fromAddress,
			"to": contractAddress,
			"value": "0x0",
			"data": params ? methods[fn](...params).encodeABI() : methods[fn]().encodeABI()
		}];
		let {
			code,
			result
		} = await sendTransaction(txObject);
		if (code) {
			if (!returnReceipt) return result; // 直接返回HASH
			return inquire(result) // 返回收据
		} else {
			return code
		}
	} catch (error) {
		console.log(`${fn}--contractAddress,params===`, [contractAddress, ...params])
		return doError(error)
	}
}

//获取store里的地址
export function getAddress(name) {
	if (!store.state.general.addressConfig) {
		router.push('/pres');
		return
	}
	return store.state.general.addressConfig[name]
}

//异步交易 eth_sendTransaction
export async function sendAsync(params) {
	try {
		const hash = await ethereum.request({ method: "eth_sendTransaction", params });
		// const hash = await web3.eth.sendTransaction(params)
		console.log('hash===>', hash)
		if (hash) {
			return {
				code: true,
				result: hash
			}
		}
	} catch (e) {
		console.log(`sendAsync--params===`, [...params])
		doError(e);
		return { code: false }
		// throw (e) // 抛出错误
	}
}

// 发起交易--web3.eth.sendTransaction
export async function sendTransaction(params) {
	try {
		const hash = await web3.eth.sendTransaction(params)
		if (hash) {
			return {
				code: true,
				result: hash
			}
		}
	} catch (e) {
		throw (e) // 抛出错误
	}
}

/**
 * 结果查询
 *@param {string} -transactionHash 交易HASH
 *@param {string} -tips  提示语   默认链上查询中
 **/
export function inquire(transactionHash='0x74202a60a2f85cbc1cf8a4a22647b5c7414ea155b1bf51fcc036704388c8edf5', tips = 'Waiting for on-chain confirmation') {
	const shalou = "https://s3.us-west-1.amazonaws.com/agi.love/img/default/shalou.png";
	const key = 'updatable';
	try {
		return new Promise((resolve, reject) => {
			message.loading({ content: tips, key,duration: 1000 });
			let a = setInterval(() => {
				web3.eth.getTransactionReceipt(transactionHash).then(res => {
					if (res && res.status) {
						console.log('结果查询===>', res)
						message.success({ content: 'Loaded!', key, duration: 0 })
						clearInterval(a);
						resolve({
							code: true,
							hash: transactionHash,
							result: res
						});
					} else {
						// Dialog.alert({
						//   className: 'model-loading',
						//   allowHtml: true,
						//   message: '<div class="loadingchaxun"><p><img width="90" src=' +
						//     shalou +
						//     ` /></p><p class="tips"></p><p class="chaxun">${tips}......</p></div>`,
						//   showConfirmButton: false,
						//   showCancelButton: false
						// });
					}
				});
			}, 500);
		});
	} catch (e) {
		console.log(e)
		resolve({
			code: false
		});
	}
}

/**
 * 无弹窗查询
 *@param {string} -transactionHash 交易HASH
 **/
export function inquireNo(transactionHash) {
	console.log('无弹窗查询Hah===>', transactionHash)
	try {
		return new Promise((resolve, reject) => {
			let a = setInterval(() => {
				web3.eth.getTransactionReceipt(transactionHash).then(res => {
					if (res && res.status) {
						console.log('inquireNo===>', res)
						clearInterval(a);
						resolve({
							code: true,
							hash: transactionHash,
							result: res
						});
					} else {

					}
				});
			}, 500);
		});
	} catch (e) {
		console.log(e)
		resolve({
			code: false
		});
	}
}

/**
 * 获取结果 logs
 *@param {string} -transactionHash 交易HASH
 *@param {boolen} -code  状态
 **/
export async function getLotteryRe(code, transactionHash) {
	try {
		if (code) {
			// 等待返回结果
			let { code: inCode, result: inResult } = await inquireNo(transactionHash);
			console.log('inResult=============>', inCode, inResult)
			if (inCode) {
				//logs为空  没有正确的返回结果
				if (inResult["logs"].length <= 0) {
					return {
						code: false
					};
				}
				//获取地址logs  得到ID
				let { data, address, topics } = inResult["logs"].pop();
				// let [, , , baseID] = topics; // 取第4位
				// console.log('address===>', address); //地址
				// console.log('baseID===>', baseID); //数组
				console.log('data===>', data, decodeParameters(['uint256', 'address'], data));
				return {
					code: true,
					address,
					// baseID: parseInt(baseID)
					data: decodeParameters(['uint256', 'address'], data)
				};
			}
		} else {
			return {
				code: false
			};
		}
	} catch (e) {
		return {
			code: false
		};
	}
}

/**
 * 小数转大数
 * @param {*} num
 * @param {*} digit
 * @returns
 */
export function toWei(num, digit = 0) {
	if (!num || num <= 0) return 0;
	if (digit <= 0) return num;
	let strNum = num.toString().split(".");
	if (strNum.length == 1) {
		return strNum[0] + "" + digitNum(digit);
	} else {
		strNum = strNum[0] + strNum[1] + digitNum(digit - strNum[1].length);
	}
	return strNum;
}

/**
 * 大数转小数
 * @param {*} num
 * @param {*} digit
 * @returns
 */
export function fromWei(num, digit = 0) {
	if (!num || num <= 0) return 0;
	if (digit <= 0) return num;
	let strNum = num.toString();
	let length = strNum.length;
	if (strNum.indexOf(".") == -1) {
		//返回值没有小数点
		if (length <= digit) {
			strNum = "0." + digitNum(digit - length) + "" + strNum;
		} else {
			strNum = strNum.substring(0, strNum.length - digit) + "." + strNum.slice(strNum.length - digit);
		}
	} else { //返回值小于位数的情况 即有小数点
		let a = strNum.split('.')[0];
		let b = strNum.split('.')[1];
		if (a.length <= digit) {
			strNum = "0." + digitNum(digit - a.length) + '' + a + b;
		} else {
			strNum = a.substring(0, a.length - digit) + "." + a.slice(a.length - digit) + b;
		}
	}
	return handleCutZero(strNum);
}

function digitNum(digit) {
	let a = "";
	for (let i = 0; i < digit; i++) {
		a += "0";
	}
	return a;
}

/**
 * 用 JSON 接口 及给定的参数编码函数调用
 */
export function encodeFunctionCall(jsonInterface, parameters) {
	try {
		let res = web3.eth.abi.encodeFunctionCall(jsonInterface, parameters);
		console.log('encodeFunctionCall===>', res)
		return res;
	} catch (error) {
		console.log('encodeFunctionCall err===>', error)
	}
}
//     encodeFunctionCall({
//     name: 'transfer',
//     type: 'function',
//     // type: 'event',
//     inputs: [{
//         type: 'address',
//         name: 'address'
//     },{
//         type: 'uint256',
//         name: 'amount'
//     }]
// }, ['0xbf21426671fc60cbe307c19b0c6c8dd7e408e85f', '123000000000000000000'])
//  0xa9059cbb000000000000000000000000bf21426671fc60cbe307c19b0c6c8dd7e408e85f000000000000000000000000000000000000000000000006aaf7c8516d0c0000

/**
 * encodeParameters
 * @param {*} typesArray  参数类型数组，或JSON接口输出数组
 * @param {*} parameters  需要编码的参数
 */
export function encodeParameters(typesArray, parameters) {
	try {
		let res = web3.eth.abi.encodeParameters(typesArray, parameters);
		console.log('encodeParameters===>', res)
		return res;
	} catch (error) {
		console.log('encodeParameters err===>', error)
	}
}
// encodeParameters(['address', 'uint256'],['0xbf21426671fc60cbe307c19b0c6c8dd7e408e85f','123000000000000000000'])
// res 0x000000000000000000000000bf21426671fc60cbe307c19b0c6c8dd7e408e85f000000000000000000000000000000000000000000000006aaf7c8516d0c0000

/**
 * 将ABI编码的参数解码为其JavaScript形式
 * @param {*} typesArray 参数类型数组，或JSON接口输出数组
 * @param {*} hexString  要解码的ABI字节码
 * return Object - 包含解码后参数值的对象
 */
export function decodeParameters(typesArray, hexString) {
	// console.log('typesArray===>',typesArray)
	// console.log('hexString===>',hexString)
	try {
		let res = web3.eth.abi.decodeParameters(typesArray, hexString);
		console.log('decodeParameters===>', res)
		return res;
	} catch (error) {
		console.log('decodeParameters err===>', error)
	}
}
/**
 * web3.eth.abi.decodeParameters(['string', 'uint256'], '0x000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000ea000000000000000000000000000000000000000000000000000000000000000848656c6c6f212521000000000000000000000000000000000000000000000000');
> Result { '0': 'Hello!%!', '1': '234' }

web3.eth.abi.decodeParameters([{
    type: 'string',
    name: 'myString'
},{
    type: 'uint256',
    name: 'myNumber'
}], '0x000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000ea000000000000000000000000000000000000000000000000000000000000000848656c6c6f212521000000000000000000000000000000000000000000000000');
> Result {
    '0': 'Hello!%!',
    '1': '234',
    myString: 'Hello!%!',
    myNumber: '234'
}
 */

/**
 * @name: handleCutZero
 * @description:  去掉double类型小数点后面多余的0参数：old 要处理的字符串或double返回值：newStr 没有多余零的小数或字符串
 * @param {*} num
 * @return {*}num
 */
function handleCutZero(num) {
	//拷贝一份 返回去掉零的新串
	let newstr = num;
	//循环变量 小数部分长度
	let leng = num.length - num.indexOf('.') - 1;
	//判断是否有效数
	if (num.indexOf('.') > -1) {
		//循环小数部分
		for (let i = leng; i > 0; i--) {
			//如果newstr末尾有0
			if (
				newstr.lastIndexOf('0') > -1 &&
				newstr.substr(newstr.length - 1, 1) == 0
			) {
				let k = newstr.lastIndexOf('0');
				//如果小数点后只有一个0 去掉小数点
				if (newstr.charAt(k - 1) == '.') {
					return newstr.substring(0, k - 1);
				} else {
					//否则 去掉一个0
					newstr = newstr.substring(0, k);
				}
			} else {
				//如果末尾没有0
				return newstr;
			}
		}
	}
	return num;
}

/**
 * 生成一个从 start 到 end 的连续数组
 * @param start
 * @param end
 */
export async function generateArray(start, end) {
	return Array.from(new Array(end + 1).keys()).slice(start)
}

/**
 * 字符串 前面2位  替换 0x
 */
export function replaceStr(str) {
	return str.replace(/^.{2}/g, "0x")
}