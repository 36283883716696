<template>
  <div class="c-solution md:mx-0 md:mt-0 md:mb-0">
    <div class="content">
      <div class="title-info md:px-12">
        <div class="flex items-center"><span class="dot"></span>如何工作</div>
        <div class="title font-bold">解决“困境四边形”问题的方案</div>
        <div class="desc">BP-FLAC致力于解决困境四边形问题并实现更好的平衡</div>
      </div>


      <div class="list-wrap md:mx-12">
        <div class="item-content">
          <div class="items">
            <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-1.png" alt="">
            <div class="item-title">数据隐私保护</div>
            <div class="description">零知识证明解决数据隐私问题，不用担心数据泄露</div>
          </div>
          <div class="items md:mt-60">
            <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-2.png" alt="">
            <div class="item-title">偏见和公平性</div>
            <div class="description">通过DAO知识实现人类的共同理念并消除偏见</div>
          </div>
        </div>
        <div class="item-content mt-80 md:mt-60">
          <div class="items">
            <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-3.png" alt="">
            <div class="item-title">缺乏可解释性</div>
            <div class="description">AI模块通用解决“黑箱”问题。实施数千次是最好的解释。</div>
          </div>
          <div class="items md:mt-60 md:pb-60">
            <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-4.png" alt="">
            <div class="item-title">资源密集型</div>
            <div class="description">突破计算能力壁垒，突破地缘政治，实现大型模型造福全人类。</div>
          </div>
        </div>
      </div>
      <img class="bg-1 md:hidden" src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-1.png" alt="">
      <img class="bg-1-m hidden md:block" src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-m-1.png" alt="">
      <img class="bg-2" src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-2.webp" alt="">
    </div>
    <div class="introduce-wrap">
      <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-3.png" alt="">
      <div class="introduce font-bold">
        <div class="flex items-center justify-center">
          <span class="text-dot"></span>
          <span class="question text-14">我们的愿景</span>
        </div>
        <div class="md:px-40">
          <Language class="md:text-24 md:leading-30" text="给所有人提供由顶级人才开发的，具有最强的算力的[r1]AGI产品[/r1]">
            <span slot="r1" class="light-color" slot-scope="data">{{ data.value }}</span>
          </Language>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Language from '@/components/common/Language'
export default {
  components: {
    Language
  }
}
</script>

<style lang="scss" scoped>
.c-solution {
  color: white;
  margin-top: 197px;
  margin: 197px 75px 100px 75px;

  .content {
    // max-width: 1770px;
    margin: 0 auto;
    position: relative;

    .title-info {
      max-width: 1920px;
      margin: 0 auto;
    }

    .bg-1 {
      position: absolute;
      right: -75px;
      z-index: 1;
      top: 20px;
    }

    .bg-1-m {
      position: absolute;
      top: 90px;
      right: 0;
    }

    .bg-2 {
      position: absolute;
      left: -75px;
      bottom: -180px;
      z-index: 1;

      @screen md {
        width: 240px;
        bottom: -180px;
        left: -20px;
      }
    }
  }

  .introduce-wrap {
    text-align: center;
    padding-top: 177px;

    img {
      margin: 0 auto;
      
      @screen md {
        width: 200px;
      }
    }

    .introduce {
      font-size: 54px;
      line-height: 68px;
      position: relative;
      top: -126px;
      max-width: 1280px;
      margin: 0 auto;

      .question {
        line-height: 22px;
      }

      .text-dot {
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 6px;
        background-color: #253bff;
        margin-right: 7px;
      }

      .light-color {
        color: #253BFF;
      }
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 6px;
    background-color: #253bff;
    margin-right: 7px;
  }

  .title {
    font-size: 54px;
    line-height: 68px;
    margin-bottom: 40px;

    @screen md {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }

  .desc {
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);

    @screen md {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .list-wrap {
    height: 593px;
    background-color: rgba(26, 23, 40, 0.8);
    border-radius: 20px;
    margin-top: 78px;
    padding: 75px 158px;
    max-width: 1920px;
    margin: 78px auto 0 auto;

    backdrop-filter: blur(45px);
    -webkit-backdrop-filter: blur(45px);
    position: relative;
    z-index: 2;

    @screen md {
      padding: 37px 28px;
      height: auto;
    }

    .item-content {
      display: flex;

      @screen md {
        display: block;
      }


      .items {
        flex: 1;

        img {
          width: 60px;
        }
      }

      .item-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: white;
        margin-top: 20px;
        margin-bottom: 12px;

        @screen md {
          font-size: 20px;
        }
      }

      .description {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        max-width: 440px;

        @screen md {
          font-size: 12px;
        }
      }
    }
  }
}</style>