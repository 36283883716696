<template>
	<div class="c-partnership">
		<div class="partner-title font-bold">合作伙伴</div>
		<div class="partner-list">
			<div class="list-content md:hidden">
				<a href="https://www.nvidia.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316082.png" alt=""></a>
				<a href="https://www.amazon.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316083.png" alt=""></a>
				<a href="https://www.eurekapartners.xyz" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316084.png" alt=""></a>
				<a href="https://westlabs.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316085.png" alt=""></a>
				<a href="https://izumi.finance" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316086.png" alt=""></a>
				<a href="https://port3.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316087.png" alt=""></a>
				<a href="https://nfprompt.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316088.png" alt=""></a>
				<a href="https://gptxai.fun" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316075.png" alt=""></a>
				<a href="https://bubbleai.xyz" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316076.png" alt=""></a>
				<a href="https://taskon.xyz" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316077.png" alt=""></a>
				<a href="https://www.atem.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316079.png" alt=""></a>
				<a href="https://legendofarcadia.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316080.png" alt=""></a>
				<a href="https://trantor.xyz" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316081.png" alt=""></a>
				<a href="https://www.gabby.world" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316078.png" alt=""></a>
				<a href="https://www.cassava.network" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316094.png" alt=""></a>
				<a href="https://petaverse.network" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316089.webp" alt=""></a>
				<a href="https://www.vip3.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316090.png" alt=""></a>
				<a href="https://fbbank.cc" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316091.png" alt=""></a>
				<a href="https://3gpt.ai" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316092.png" alt=""></a>
				<a href="https://seer.eco" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316095.png" alt=""></a>
				<a href="https://www.sparkle.fun" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316093.png" alt=""></a>
				<a href="https://www.shareverse.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316083-1.png" alt=""></a>
				<a href="https://www.xplus.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316082-1.png" alt=""></a>
				<a href="https://www.rido.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316084-1.png" alt=""></a>
				<a href="https://nextme.one" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316085-1.png" alt=""></a>
				<a href="https://intoverse.co" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316086-1.png" alt=""></a>
				<a href="https://sypool.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316087-1.png" alt=""></a>
				<a href="https://www.nvirworld.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/nvirworld.png" alt=""></a>
				<a href="https://quantlytica.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/quantlytica-logo.svg" alt=""></a>

			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center px-40">
				<a href="https://www.nvidia.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316082.png" alt=""></a>
				<a href="https://www.amazon.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316083.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://www.eurekapartners.xyz/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316084.png" alt=""></a>
				<a href="https://westlabs.io/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316085.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://izumi.finance/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316086.png" alt=""></a>
				<a href="https://port3.io/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316087.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://nfprompt.io/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316088.png" alt=""></a>
				<a href="https://gptxai.fun/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316075.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://bubbleai.xyz/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316076.png" alt=""></a>
				<a href="https://taskon.xyz/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316077.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://www.atem.io/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316079.png" alt=""></a>
				<a href="https://legendofarcadia.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316080.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://trantor.xyz/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316081.png" alt=""></a>
				<a href="https://www.gabby.world" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316078.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://www.cassava.network" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316094.png" alt=""></a>
				<a href="https://petaverse.network/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316089.webp" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://www.vip3.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316090.png" alt=""></a>
				<a href="https://fbbank.cc/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316091.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://3gpt.ai/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316092.png" alt=""></a>
				<a href="https://seer.eco/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316095.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://www.sparkle.fun/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316093.png" alt=""></a>
				<a href="https://www.shareverse.io" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316083-1.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://www.xplus.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316082-1.png" alt=""></a>
				<a href="https://www.rido.io/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316084-1.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://nextme.one/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316085-1.png" alt=""></a>
				<a href="https://intoverse.co/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316086-1.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://sypool.io/" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/Frame-1321316087-1.png" alt=""></a>
				<a href="https://www.nvirworld.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/nvirworld.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a href="https://quantlytica.com" target="_blank"><img src="https://s3.us-west-1.amazonaws.com/agi.love/Partnership/quantlytica-logo.svg" alt=""></a>
			</div>
		</div>
	</div>
</template>

<script>
	import Button from '@/components/Button.vue'
	export default {
		components: {
			Button,
		}
	}
</script>

<style lang="scss" scoped>
	.c-partnership {
		color: white;
		position: relative;

		.partner-title {
			font-size: 54px;
			line-height: 68px;
			color: white;
			text-align: center;
			margin-top: 149px;
			margin-bottom: 50px;

			@screen md {
				font-size: 24px;
				line-height: 32px;
				margin-top: 130px;
			}
		}

		.partner-list {
			margin: 0 auto;
			max-width: 1600px;

			.list-content {
				/* padding: 0 75px; */
				display: flex;
				flex-direction: row;
				align-items: stretch;
				justify-content: center;
				flex-wrap: wrap;
				align-content: space-around;
				gap: 26px;
				border-bottom: 1px solid rgba(58, 61, 66, 0.5);
				padding-bottom: 110px;

				a {
					width: 206px;
					height: 94px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #ffffff;
					/* margin: 0 30px; */
					/* margin-bottom: 28px; */

					img {
						/* max-height: 50px; */
						width: 100%;
					}
				}
			}

			.mobile-logo {
				a {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				img {
					max-width: 100px;
					/* max-height: 30px; */
				}
			}

			@screen md {
				img {
					transform: scale(1) !important;
					max-height: 38px;
				}
			}

			@media screen and (min-width: 2070px) {
				padding: 0;
			}

			@media screen and (max-width: 1440px) {
				img {
					transform: scale(0.7);
				}
			}
		}

		.c-button {
			width: 440px;
			margin-top: 120px;

			@screen md {
				margin-top: 80px;
				width: 280px;
			}

			&:hover {
				width: 490px;

				@screen md {
					width: 280px;
				}
			}

			&.ru {
				width: 350px;

				&:hover {
					width: 400px;

					@screen md {
						width: 350px;
					}
				}
			}
		}

		.productivity {

			padding: 93px 75px;
			box-sizing: border-box;
			max-width: 1920px;
			margin: 0 auto;

			@screen md {
				padding: 50px 12px;
			}

			.content {
				position: relative;
				z-index: 1;
				height: 637px;

				@screen md {
					height: 544px;
					box-sizing: border-box;
				}
			}

			@media screen and (min-width: 2070px) {
				padding: 93px 0;
			}

			&::after {
				background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/productivity-bg.webp');
				display: block;
				height: 730px;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				z-index: 0;
				background-repeat: no-repeat;
				background-size: cover;

				@screen md {
					height: 594px;
					background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/productivity-bg-m.webp')
				}
			}

			.title {
				font-size: 54px;
				line-height: 68px;
				color: white;
				font-weight: bold;

				@screen md {
					font-size: 24px;
					line-height: 32px;
				}
			}

			.desc {
				font-size: 20px;
				line-height: 22px;
				color: rgba($color: #ffffff, $alpha: 0.6);
				margin-top: 12px;
				margin-bottom: 110px;

				@screen md {
					font-size: 12px;
					line-height: 20px;
					margin-bottom: 68px;
				}
			}

			.list--item {
				display: flex;
				align-items: center;
				font-size: 24px;
				line-height: 32px;
				max-width: 800px;
				flex: 1;
				position: relative;

				@screen md {
					padding-bottom: 20px;
				}

				&::after {
					display: block;
					content: '';
					height: 1px;
					background-color: rgba(255, 255, 255, 0.4);
					position: absolute;
					left: 0;
					right: 0;
					bottom: -30px;

					@screen md {
						bottom: 0;
					}
				}

				&+.list--item {
					margin-left: 80px;
				}

				img {
					margin-inline-end: 31px;
				}
			}
		}
	}
</style>