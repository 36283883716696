
/**
 * @function 小数位保留
 * @param num    //当前数
 * @param digit  //保留位数
 * @param adapt  //是否补0
 * @returns {number}
 */
export function integer(num, digit, adapt = false) {
  num = num || 0;
  digit = digit || 2;
  let strNum = num.toString().split(".");
  if (strNum.length == 1) {
    strNum.push("00000000000000000000000000000000000");
  } else {
    strNum[1] = strNum[1] + "00000000000000000000000000000000000";
  }
  if (adapt) {
    strNum = strNum[0] + "." + strNum[1].substr(0, digit);
  } else {
    strNum = handleCutZero(strNum[0] + "." + strNum[1].substr(0, digit));
  }
  return strNum;
}


/**
 * @param num    //当前数
 * @returns {number}
 */
export function rp(num) {
  switch (num) {
    case 1:
      return "一";
    case 2:
      return "二";
    case 3:
      return "三";
    case 4:
      return "四";
    case 5:
      return "五";
    case 6:
      return "六";
    case 7:
      return "七";
    case 8:
      return "八";
    case 9:
      return "九";
    default:
      return "零";
  }
}

/**
 * @function 时间格式化
 * @param date    //传入的时间
 * @param fmt     //格式  （yyyy-MM-dd）
 * @returns {*}
 */
export function formatDate(date, fmt = 'yyyy-MM-dd') {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).slice(str.length);
}

/**
 * @function 数字格式化
 * @param num    //传入的数字
 * @param point     //小数位数
 *  console.log(tranNumber(1000,2)) // 1000
    console.log(tranNumber(26742238,2)) // 2674.22万
    console.log(tranNumber(1234787325,2)) // 12.34亿
 */
export function tranNumber(num, point) {
  num = !num ? 0 : num;
  // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
  let numStr = num.toString().split(".")[0];
  if (numStr.length < 5) {
    // 判断数字有多长,如果小于5,表示1万以内的数字,让其直接显示
    return numStr;
  } else if (numStr.length >= 5 && numStr.length <= 8) {
    // 如果数字大于5位,小于8位,让其数字后面加单位万
    let decimal = numStr.substring(
      numStr.length - 4,
      numStr.length - 4 + point
    );
    // 由千位,百位组成的一个数字
    return parseFloat(parseInt(num / 10000) + "." + decimal) + "万";
  } else if (numStr.length > 8) {
    // 如果数字大于8位,让其数字后面加单位亿
    let decimal = numStr.substring(
      numStr.length - 8,
      numStr.length - 8 + point
    );
    return parseFloat(parseInt(num / 100000000) + "." + decimal) + "亿";
  }
}

/**
 * @function 时间转换成几秒前、几分钟前、几小时前、几天前等格式
 * @param {date} dateStr 
 */
export function getDateDiff(dateStr) {
  var publishTime = Date.parse(dateStr),
    d_seconds,
    d_minutes,
    d_hours,
    d_days,
    timeNow = parseInt(new Date().getTime() / 1000),
    d,
    date = new Date(publishTime),
    Y = date.getFullYear(),
    M = date.getMonth() + 1,
    D = date.getDate(),
    H = date.getHours(),
    m = date.getMinutes(),
    s = date.getSeconds();
  //小于10的在前面补0
  if (M < 10) {
    M = "0" + M;
  }
  if (D < 10) {
    D = "0" + D;
  }
  if (H < 10) {
    H = "0" + H;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }

  d = timeNow - publishTime / 1000;
  d_days = parseInt(d / 86400);
  d_hours = parseInt(d / 3600);
  d_minutes = parseInt(d / 60);
  d_seconds = parseInt(d);

  if (d_days > 0 && d_days < 10) {
    return d_days + "天前";
  } else if (d_days <= 0 && d_hours > 0) {
    return d_hours + "小时前";
  } else if (d_hours <= 0 && d_minutes > 0) {
    return d_minutes + "分钟前";
  } else if (d_seconds < 60) {
    if (d_seconds <= 0) {
      return "刚刚发表";
    } else {
      return d_seconds + "秒前";
    }
  } else if (d_days >= 10 && d_days < 30) {
    //大于10天小于30天 显示 XX-XX XX:XX
    return M + "-" + D + " " + H + ":" + m;
  } else if (d_days >= 30) {
    return Y + "-" + M + "-" + D + " " + H + ":" + m;
  }
}

/**
* @function 根据身份证号获取年龄
* @description 15位身份证号码：第7、8位为出生年份(两位数)，第9、10位为出生月份，第11、12位代表出生日
               18位身份证号码：第7、8、9、10位为出生年份(四位数)，第11、第12位为出生月份，
               第13、14位代表出生日期，第17位代表性别，奇数为男，偶数为女。
 */
export function GetAge(identityCard) {
  var len = (identityCard + "").length;
  var strBirthday = "";
  if (len == 18) {
    //处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday =
      identityCard.substr(6, 4) +
      "/" +
      identityCard.substr(10, 2) +
      "/" +
      identityCard.substr(12, 2);
  }
  if (len == 15) {
    var birthdayValue = "";
    birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
    if (parseInt(birthdayValue) < 10) {
      strBirthday =
        "20" +
        identityCard.substr(6, 2) +
        "/" +
        identityCard.substr(8, 2) +
        "/" +
        identityCard.substr(10, 2);
    } else {
      strBirthday =
        "19" +
        identityCard.substr(6, 2) +
        "/" +
        identityCard.substr(8, 2) +
        "/" +
        identityCard.substr(10, 2);
    }
  }
  //时间字符串里，必须是“/”
  var birthDate = new Date(strBirthday);
  var nowDateTime = new Date();
  var age = nowDateTime.getFullYear() - birthDate.getFullYear();
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() == birthDate.getMonth() &&
      nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

/**
 * @function 字符串格式 隐藏处理
 * @param {*} str  
 * @param {*} num 
 * @returns 
 */
export function noPassByName(str, num = 8) {
  if (null != str && str != undefined) {
    if (str.length <= 3) {
      return "*" + str.substring(1, str.length);
    } else if (str.length > 3 && str.length <= 6) {
      return "**" + str.substring(2, str.length);
    } else if (str.length > 6 && str.length < 11) {
      return (
        str.substring(0, 3) + "****" + str.substring(str.length - 3, str.length)
      );
    } else if (str.length > 16) {
      return (
        str.substring(0, 6) + "..." + str.substring(str.length - 6, str.length)
      );
    } else {
      return (
        str.substring(0, 3) + "****" + str.substring(str.length - 5, str.length)
      );
    }
  } else {
    return "";
  }
}

/**
 * @function 函数防抖
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 */
export function debounce(func, wait) {
  let timeout = null;
  return function () {
    let context = this;
    let args = arguments;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

/**
 * @function 函数节流
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 */
export function throttle(func, wait) {
  let timeout = null;
  return function () {
    let context = this;
    let args = arguments;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    }
  };
}


/**
 * @function 格式化数字N位小数的方法
 * @param {*} number:带小数的数值
 * @param {*} keepDecimalPlaces:保留小数点位数
 */
export function maxDecimal(number, keepDecimalPlaces) {
  let rep = new RegExp(`^(.*\\..{${keepDecimalPlaces}}).*$`);
  return String(number).replace(rep, "$1");
}

/**
 * @function  时间戳(秒)转化为日期格式
 * @param {Number} timeStamp  时间搓秒
 * @param {Boolean} type      true 为 yyyy-MM-dd hh:mm:ss
 * @returns 
 * @example dataFormat(1667059990)   2022-10-30 00:13:10    dataFormat(1667059990,false)   2022-10-30
 */
export function dataFormat(timeStamp, type = true) {
  var dd = new Date(parseInt(timeStamp * 1000));
  var y = dd.getFullYear();
  var M = dd.getMonth() + 1;
  var d = dd.getDate();
  var h = dd.getHours();
  var m = dd.getMinutes();
  var s = dd.getSeconds();
  M = M < 10 ? "0" + M : M;
  d = d < 10 ? "0" + d : d;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  if (type) {
    return y + "-" + M + "-" + d + " " + h + ":" + m + ":" + s;
  } else {
    return y + "-" + M + "-" + d;
  }
}

/**
 * @function 间隔时间time(s)
 * @param {Number} time 秒
 * @returns 
 */
export async function sleep(time) {
  return new Promise(resolve => {
    setTimeout(() => {
      console.log(`执行等待${time}s---`);
      resolve();
    }, time * 1000);
  });
}




/**
 * @function  数字前面补0
 * @param {Number} num  被操作数
 * @param {Number} n    固定的总位数
 * @returns 
 * @example PrefixZero(12,5) → 00012
 */
export function PrefixZero(num, n) {
  return (Array(n).join(0) + num).slice(-n);
}

/**
 * @function 数值过千加逗号，并保留几位小数
 * @description 数字每3位一个逗号隔开 便于识别位数
 * @param {Number} num 被操作数
 * @param {Number} len 
 * @param {String} str 
 * @returns 
 * @example numFormat(1245242.2412) → 1,245,242.2412
 */
export function numFormat(num, len, str) {
  let l;
  if (isNullorEmpty(num)) return '--';
  const isNegative = !(num >= 0);
  if (num.toString().split('.').length > 1) {
    l = num.toString().split('.')[1].length;
  }
  //是否负数
  if (isNegative) num = Math.abs(Number(num));
  len = len || l;
  str = str || '';
  let c;
  if (!len) {
    c = num && num !== 0 ? num.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') : '0';
  } else {
    c = num && num !== 0 ? (Math.floor(Number(num) * Math.pow(10, len)) / Math.pow(10, len)).toFixed(len).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : '0';
  }
  //是否负数
  if (isNegative) c = '-' + c;
  return str + c;
}
function isNullorEmpty(str) {
  return (str === '' || str === null || str === undefined || isNaN(str));
}



export function getkeyFromvalue(object, value) {
  for (let ob in object) {
    if (object.hasOwnProperty(ob)) {
      if (object[ob] === value);
      return ob;
    }
  }
}

export async function getMedia(constraints) {
  let stream = null;
  try {
    stream = await navigator.mediaDevices.getUserMedia(constraints);
    /* use the stream */
  } catch (err) {
    /* handle the error */
  }
}

/**
 * @function 小数转大数 
 * @description 将给定资金转换为以wei为单位的数值  类似  web3.toWei 方法
 * @param {Number} num    被操作数
 * @param {Number} digit  位数
 * @returns 
 * @example 
 */
export function toWei(num, digit = 0) {
  if (!num || num <= 0) return 0;
  if (digit <= 0) return num;
  let strNum = num.toString().split(".");
  if (strNum.length == 1) {
    return strNum[0] + "" + digitNum(digit);
  } else {
    strNum = strNum[0] + strNum[1] + digitNum(digit - strNum[1].length);
  }
  return strNum;
}

/**
 * @function 大数转小数 
 * @description 以太坊货币单位之间的转换。将以wei为单位的资金，转换为指定单位的数值 类似  web3.fromWei 方法
 * @param {Number} num    被操作数
 * @param {Number} digit  位数
 * @returns 
 * @example 
 */
export function fromWei(num, digit = 0) {
  if (!num || num <= 0) return 0;
  if (digit <= 0) return num;
  let strNum = num.toString();
  let length = strNum.length;
  if (strNum.indexOf(".") == -1) {
    //返回值没有小数点
    if (length <= digit) {
      strNum = "0." + digitNum(digit - length) + "" + strNum;
    } else {
      strNum = strNum.substring(0, strNum.length - digit) + "." + strNum.slice(strNum.length - digit);
    }
  } else {//返回值小于位数的情况 即有小数点
    let a = strNum.split('.')[0];
    let b = strNum.split('.')[1];
    if (a.length <= digit) {
      strNum = "0." + digitNum(digit - a.length) + '' + a + b;
    } else {
      strNum = a.substring(0, a.length - digit) + "." + a.slice(a.length - digit) + b;
    }
  }
  return handleCutZero(strNum);
}

/**
  * @name: handleCutZero
  * @description:  去掉double类型小数点后面多余的0参数：old 要处理的字符串或double返回值：newStr 没有多余零的小数或字符串
  * @param {*} num
  * @return {*}num
*/
function handleCutZero(num) {
  //拷贝一份 返回去掉零的新串
  let newstr = num;
  //循环变量 小数部分长度
  let leng = num.length - num.indexOf('.') - 1;
  //判断是否有效数
  if (num.indexOf('.') > -1) {
    //循环小数部分
    for (let i = leng; i > 0; i--) {
      //如果newstr末尾有0
      if (
        newstr.lastIndexOf('0') > -1 &&
        newstr.substr(newstr.length - 1, 1) == 0
      ) {
        let k = newstr.lastIndexOf('0');
        //如果小数点后只有一个0 去掉小数点
        if (newstr.charAt(k - 1) == '.') {
          return newstr.substring(0, k - 1);
        } else {
          //否则 去掉一个0
          newstr = newstr.substring(0, k);
        }
      } else {
        //如果末尾没有0
        return newstr;
      }
    }
  }
  return num;
}

function digitNum(digit) {
  let a = "";
  for (let i = 0; i < digit; i++) {
    a += "0";
  }
  return a;
}

/**
 * @method JS获取URL链接参数
 * @param {String} url  链接
 * @param {String} key  属性key 非必传
 * @returns  参数对象 || 对应的属性
 */
export function queryURLParams(url, key = '') {
  let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
  let parames = {}; // 定义参数对象
  url.replace(pattern, ($, $1, $2) => {
    parames[$1] = $2;
  });
  return key ? parames[key] : parames;
}

/**
 * 清除所有cookies
 */
export function delCookie() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
  if (cookies.length > 0) {
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      var domain = location.host.substr(location.host.indexOf("."));
      document.cookie =
        name +
        "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
        domain;
    }
  }
}

/**
 * 清除所有localStorage
 */
export function delLocalStorage(){
  window.localStorage.clear();
}

/**
 * @function 获取localStorage和sessionStorage当前已存储大小默认获取localStorage
 * get_cache_size('l'); //localStorage当前大小
 * get_cache_size('s'); //sessionStorage当前大小
 * @param {string} t
 */
 export function get_cache_size(t) {
  t = t == undefined ? "l" : t;
  var obj = "";
  if (t === "l") {
    if (!window.localStorage) {
      console.log("浏览器不支持localStorage");
    } else {
      obj = window.localStorage;
    }
  } else {
    if (!window.sessionStorage) {
      console.log("浏览器不支持sessionStorage");
    } else {
      obj = window.sessionStorage;
    }
  }
  if (obj !== "") {
    var size = 0;
    for (var item in obj) {
      if (obj.hasOwnProperty(item)) {
        size += obj.getItem(item).length;
      }
    }
    console.log(
      `当前${t == "t" ? "localStorage" : "sessionStorage"}已用存储：` +
      (size / 1024).toFixed(2) +
      "KB"
    );
  }
}
/**
 * @function 获取localStorage最大存储量
 */
export function getlocalStorageMax() {
  if (!window.localStorage) {
    console.log("当前浏览器不支持localStorage!");
  }
  var test = "0123456789";
  var add = function (num) {
    num += num;
    if (num.length == 10240) {
      test = num;
      return;
    }
    add(num);
  };
  add(test);
  var sum = test;
  var show = setInterval(function () {
    sum += test;
    try {
      window.localStorage.removeItem("test");
      window.localStorage.setItem("test", sum);
      console.log(sum.length / 1024 + "KB");
    } catch (e) {
      console.log(sum.length / 1024 + "KB超出最大限制")
      clearInterval(show);
    }
  }, 0.1);
}
// @function 获取
export function getQueryString(url,name){
  console.log('url',url)
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = url.split('?')[1].match(reg);
  console.log('r===>',r)
  if (r != null) {
      return unescape(r[2]);
  };
  return null;
}


/**
 * @description: 数组通过 key值去重
 * @param {Array} arr 要去重的数组
 * @param {String} uniqueKey 唯一标识
 * @return: arr 去重后的数组
 */
export function removalRepeat(arr, uniqueKey) {
  let map = new Map();
  for (let i of arr) {
    if (!map.has(i[uniqueKey])) {
      map.set(i[uniqueKey], i);
    }
  }
  arr = [...map.values()];
  return arr;
}