import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "tailwindcss/tailwind.css";
import "@/assets/index.css";
import "@/assets/base.scss";
import store from './store'

import lang from './plugins/lang'
import ui from './plugins/ui'
import tools from './plugins/tools'
import filter from './filters/index.js'

import device from "vue-device-detector"

// 通用提示弹窗 命令式组件
import Prompt from './prompt/index'

 /**
 * 全局方法
*/
import * as fn from '@/utils/common.js' // global 方法
Object.keys(fn).forEach(key => {
  Vue.prototype['$'+key] = fn[key]
})

/**
 * 精度问题
 * 调用方法
 * ****加-- - $calc.Add(arg1, arg2, d)
 * ****减-- - $calc.Sub(arg1, arg2, d)
 * ****乘-- - $calc.Mul(arg1, arg2, d)
 * ****除-- - $calc.Div(arg1, arg2, d)
 */
import calc from '@/utils/math.js'
Vue.prototype.$calc = calc;

// vConsole调试工具
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

//   全局指令
import "@/directives/index.js"

Vue.use(Prompt)

Vue.config.productionTip = false

Vue.use(lang);
Vue.use(filter);
Vue.use(tools);
Vue.use(ui);
Vue.use(device)



new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');