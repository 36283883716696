import chainBase from './ChainCfg';
// import { Toast} from "@/utils/tools.js";
import { message } from 'ant-design-vue';
// 添加链节点
export const changeNetwork = async id => {
    console.log('id====>', id);
    let cfg = chainBase[id];
    if (!window.ethereum) return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request = window.ethereum.request;
    // 获取当前链id
    const chainId = await request({ method: 'eth_chainId' });
    console.log(`chainId====>:${chainId.toString(10)}`);
    console.log('cfg===>', cfg);
    if (chainId !== cfg.chainId) message.info(`正在切换链为:${cfg.chainName}`);
    try {
        // 切换
        let res = await request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: cfg.chainId }],
        });
        console.log('切换res===>', res);
        return true;
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message.error(`ERROR:${e.message}`);
        return false;
    }
};
