<template>
    <div class="model-bridge-campaign">
        <div class="bridge-campaign-content">
            <img class="topImg" src="../../assets/img/login/noLogin.png" alt="" />
            <p class="title">
                <template v-if="type == 1">{{lang=='en'?'If you are not connected to a wallet, please connect your wallet first':'未连接钱包，请先连接钱包'}}</template>
                <template v-if="type == 2">{{lang=='en'?'You can only access the dapp browser through the BSC network whether to switch wallets':'只能通BSC网络的dapp浏览器才能访问 是否切换钱包'}}</template>
            </p>
            <Mbutton @click="submitFn" :loading="loading" :value="BtnText" />
        </div>
    </div>
</template>
<script>
import Mbutton from '@/components/Mbutton.vue';
import { mapState } from 'vuex';
export default {
    name: 'ModelSign',
    inject: ['reload'],
    props: {
        loading: {
            default: false,
            type: Boolean,
        },
        value: {
            default: '',
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        type: {
            default: 1, // 1 没有登录  2  错误的chainID
            type: Number,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['address','lang']),
        // ...mapState({
        //     address: state => state.address,
        // }),
        BtnText() {
            if (this.type == 1) return this.lang=='en'?'Connect wallet':'连接钱包';
            if (this.type == 2) return this.lang=='en'?'Determine the switching chain':'确定切换链';
        },
    },
    created() {},
    components: {
        Mbutton,
    },
    mounted() {
    },
    methods: {
        afterClose() {
            this.$emit('closeModel');
        },
        async submitFn() {
            this.$emit('submitEvent');
        },
    },
};
</script>
<style lang="scss" scoped>
.model-bridge-campaign {
    width: 800px;
    height: 764px;
    background-color: #0e0e0e !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 35px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: #000;
    padding: 0 88px 0 106px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 965px) and (max-width: 1440px) {
        width: 800px;
        height: 700px;
        padding: 0 40px 0 60px;
    }

    @screen md {
        width: 90%;
        height: auto;
        padding: 39px 25px 35px;
    }

    .close {
        cursor: pointer;
        padding: 12px;
        position: absolute;
        top: 16px;
        right: 16px;

        img {
            width: 100%;
        }
    }

    .bridge-campaign-content {
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px 29px;
        color: #fff;
        .topImg {
            width: 50%;
            margin: 0 auto 30px;
        }
        .title {
            color: #fff;
            font-size: 16px;
        }
        .itemOne {
            display: flex;
            flex-direction: column;
            p:nth-of-type(1) {
                margin-bottom: 8px !important;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
                span {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
            & > p:nth-of-type(2) {
                width: 100%;
                background-color: #0421df;
                padding: 16px;
                border-radius: 8px;
                input {
                    background: unset;
                    width: 100%;
                }
            }
        }
        .itemOne2 {
            display: flex;
            flex-direction: column;
            p:nth-of-type(1) {
                margin-bottom: 8px !important;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
                span {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
            & > p:nth-of-type(2) {
                width: 100%;
                background-color: #29292d;
                padding: 16px;
                border-radius: 8px;
                input {
                    background: unset;
                    width: 100%;
                    outline: unset;
                }
            }
        }
    }
}
</style>
