import axios from 'axios'
import store from '../store'
import router from '../router'

const service = axios.create({
	baseURL: 'https://api.ajlab.xyz', // 
	// baseURL: 'http://117.24.6.18:5030', // 
	timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(
	config => {
		config.headers = {
			'Content-Type': 'application/json',
			'TOKEN':store.state.token?store.state.token:'000',
		};
		return config;
	},
	error => {
		return Promise.reject(error)
	}
);

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data;
		if (res.code == 'SYS-ERROR') {
			return res
		}
		if (res.code === 'notlogin') {
			store.commit('SET_TOKEN', '')
			// router.push('/');
		} else {
			// return res;
		}
		return res;
	},
	error => {
 
		return Promise.reject(error)
	}
);

export default service