<template>
    <div class="content md:mx-0 md:mt-0 md:mb-0">
        <div class="desc-content">
            <div class="content-wrap md:mx-12">
                <div class="flex items-center">
                    <span class="text-dot"></span>
                    <span class="question">{{ lang == 'en' ? 'Project Benefits' : '项目优势' }}</span>
                </div>
                <div class="flex md:block">
                    <div class="answer flex-1">
                        {{ lang == 'en' ? 'Unbelievable' : '令人难以置信的' }}
                        <br />
                         {{ lang == 'en' ? 'Absolutely' : '绝对优势' }}
                    </div>
                    <div class="right-desc md:relative md:mt-12">{{ lang == 'en' ? 'AJLabs is a rising supernova in the field of blockchain digital tokens, and an unstoppable super dark horse in the field of metaverse!' : 'AJLabs是区块链数字通证领域一颗冉冉升起的超新星是元宇宙领域一匹势不可挡的超级黑马！' }}</div>
                </div>
            </div>
        </div>
        <div class="introduce-content md:block md:px-12 md:mt-40">
            <div class="flex-1">
                <div class="box-item active item-1 flex-1 md:mb-20">
                    <div class="text"> {{ lang == 'en' ? 'Fully decentralized' : '完全去中心化' }}</div>
                    <div class="desc"> {{ lang == 'en' ? 'The contract is automatically executed, refusing human manipulation, the whole process is safe and transparent, and there is no risk of running away.' : '合约自动执行，拒绝人为操控，全程安全透明，没有跑路风险。' }}</div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-1.png" alt="" />
                </div>
                <div class="box-item item-4 md:mb-20">
                    <div class="text"> {{ lang == 'en' ? 'The cost threshold is low' : '成本门槛低廉化' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'The promotion agent can participate in 100U, there is no capital threshold, and there is no reinvestment mechanism.' : '推广代理100U即可参与，没有资金门槛，没有复投机制。' }}</div>
                    <div @click="$router.push({ name: 'ChipRaw' })">
                        <Button :class="$lang">{{ lang == 'en' ? 'Learn more' : '了解更多' }}</Button>
                    </div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-3.png" alt="" />
                </div>
            </div>
            <div class="flex-1">
                <div class="box-item item-2 md:mb-20">
                    <div class="text">{{ lang == 'en' ? 'Easier marketing' : '市场推广轻松化' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'AJ Labs has 10W+ members, 100W+ users, and 100 countries are deployed at the same time, and it is easy to reach a consensus. 0 precipitation of funds, higher market acceptance!' : 'AJ Labs已有10W+会员，100W+用户，百大国家同时布局，共识容易。资金0沉淀，市场接受度更高！' }}</div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-2.png" alt="" />
                </div>
                <div class="box-item item-3">
                    <div class="text">{{ lang == 'en' ? 'The blueprint outlook is reliable' : '蓝图前景可靠化' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'The main body has a strong endorsement, the development trend is hot, the working capital is abundant, and the policy prospect is bright.' : '主体背书强大，发展趋势火热，运营资本充裕，政策前景光明。' }}</div>
                    <div @click="$router.push({ name: 'ZkpAi' })">
                      <Button :class="$lang">{{ lang == 'en' ? 'Learn more' : '了解更多' }}</Button>
                    </div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-3.png" alt="" />
                </div>
            </div>
        </div>
        <div class="header md:pt-28">
            <div class="text font-bold md:pl-22 md:text-20">{{ lang == 'en' ? 'AJ Labs' : 'AJ实验室' }}</div>
            <div>
                <div class="desc md:ml-22 md:text-12 md:mt-8 md:pr-20">
                  {{ lang == 'en' ? 'AJ Labs is a Web 3.0 digital economy token service platform established based on technologies such as metaverse, blockchain smart contracts, decentralized finance (DeFi), NFT asset transaction confirmation, and distributed storage (IPFS).' : 'AJ Labs是基于元宇宙、区块链智能合约、去中心化金融（DeFi）、NFT资产交易确权、分布式存储（IPFS）等技术而成立的Web 3.0数字经济通证服务平台。' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue';
import { mapState } from 'vuex';
export default {
    components: {
        Button,
    },
    computed: {
        ...mapState(['lang']),
    },
};
</script>

<style lang="scss" scoped>
.content {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
}
.desc-content {
    position: relative;
    /* top: -100px; */
    top: 0;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 100px;

    @screen md {
        top: 0 !important;
        margin-top: 69px;
    }

    @media screen and (max-width: 1440px) {
        /* top: -50px; */
        margin-top: 40px;
    }

    .content-wrap {
        position: relative;
        margin: 0 75px;

        @media screen and (min-width: 2070px) {
            margin: 0;
        }
    }

    .text-dot {
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 6px;
        background-color: #253bff;
        margin-right: 7px;
    }

    .question {
        font-size: 14px;
        color: #ffffff;
    }

    .answer {
        font-size: 54px;
        line-height: 68px;
        font-weight: 600;
        color: #ffffff;
        max-width: 45%;

        @screen md {
            max-width: 100%;
            font-size: 24px !important;
            line-height: 32px !important;
        }

        @media screen and (max-width: 1440px) {
            font-size: 40px;
            line-height: 48px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .right-desc {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6);
        width: 40%;
        position: absolute;
        bottom: 0;
        right: 0;

        @screen md {
            width: 100%;
            font-size: 12px !important;
        }

        @media screen and (max-width: 1440px) {
            font-size: 16px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 14px;
        }
    }
}
.introduce-content {
    // max-width: 1770px;
    margin: 0 auto;
    display: flex;
    padding: 0 75px;
    max-width: 1920px;
    margin-bottom: 66px;

    @media screen and (min-width: 2070px) {
        padding: 0;
    }
    .flex-1 {
        display: flex;
        flex-direction: column;
    }
    .box-item {
        // flex: 1;
        border-radius: 20px;
        border: 1px solid #2e2a41;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            position: absolute;
            transform: rotateZ(180deg);
            border-radius: 20px;

            background: radial-gradient(80.07% 90.07% at 50% 100%, #000000 10%, rgba(4, 1, 19, 1) 39.26%, rgba(61, 84, 202, 0.3) 100%),
                linear-gradient(0deg, rgba(46, 42, 65, 0.9), rgba(46, 42, 65, 0.1)), linear-gradient(180deg, #d7ccf6 -4.51%, rgba(19, 8, 48, 0) 52.91%);
            z-index: 1;
            // display: none;
            opacity: 0;
            transition: opacity 3s cubic-bezier(0.26, 1.04, 0.54, 1);
        }

        .text {
            font-size: 32px;
            font-weight: bold;
            line-height: 38px;
            color: white;
            padding-left: 67px;
            padding-top: 47px;
            transition: all 0.3s;
            position: relative;
            z-index: 2;
            max-width: 300px;

            @screen md {
                padding-left: 23px;
                padding-top: 28px;
                font-size: 20px;
                line-height: 24px;
            }
        }

        .desc {
            font-size: 16px;
            line-height: 24px;
            margin-top: 37px;
            // max-width: 473px;
            color: #a0a3ac;
            padding-left: 67px;
            transition: all 0.3s;
            position: relative;
            z-index: 2;
            max-width: 640px;
            margin-bottom: 29px;
            @screen md {
                font-size: 12px;
                padding: 0 23px;
                margin-top: 8px;
                line-height: 20px;
            }
        }
        .c-button {
            margin-left: 67px;
            @screen md {
                margin-left: 23px;
            }
        }

        &:hover,
        &.active {
            &::after {
                // display: block;
                opacity: 1;
            }
            .text {
                color: transparent;
                background-clip: text;
                display: inline-block;
                background-image: radial-gradient(98.96% 1459.2% at 102.6% 50%, #33c4f2 0%, #1f35fd 100%);
            }

            .desc {
                color: white;
            }
        }

        &.item-1 {
            height: 320px;
            margin-right: 50px;
            margin-bottom: 34px;

            @screen md {
                height: 293px;
                margin-right: 0;
            }

            img {
                position: absolute;
                right: -90px;
                bottom: -30px;
            }
        }

        &.item-2 {
            border: 1px solid #2e2a41;
            min-height: 274px;
            border-radius: 20px;
            margin-bottom: 34px;

            img {
                position: absolute;
                right: 14px;
                top: 0;
            }
        }

        &.item-3 {
            min-height: 347px;
            padding-bottom: 30px;
            img {
                position: absolute;
                right: 14px;
                bottom: 0;
            }
            &::after {
                display: none;
            }
        }
        &.item-4 {
            min-height: 298px;
            margin-right: 50px;
            padding-bottom: 30px;
            @screen md {
                height: 293px;
                margin-right: 0;
            }
            img {
                position: absolute;
                right: 14px;
                bottom: 0;
            }
            &:hover {
                &::after {
                    display: none;
                }
            }
        }
    }
}
.header {
    height: 156px;
    width: 100%;
    background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-header.png');
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px;
    background-size: cover;
    margin-bottom: 200px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @screen md {
        display: block;
        background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-header-m.png');
        height: 293px;
    }

    div {
        flex: 1;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #a0a3ac;
    }

    .text {
        font-size: 32px;
        line-height: 38px;
        color: white;
        width: 874px;
        padding-left: 57px;

        @screen md {
            width: auto;
        }
    }

    .desc {
        max-width: 480px;
        margin-left: 64px;
    }
}
</style>
