<template>
  <div class="model-bridge-campaign">
    <div class="close md:w-24 md:p-0" @click.stop="afterClose">
      <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/close.svg" alt="">
    </div>
    <div class="bridge-campaign-content">
      <div class="content-left">
        <h1><span>AJ Labs</span> {{lang=='en'?'Staking packages':'质押套餐'}}</h1>
        <span class="second">{{lang=='en'?'BSC mainnet has been launched':'已上线BSC主网'}}</span>
        <span class="slogon">{{lang=='en'?'Participate in staking and get more rewards':'参与质押，获得更多奖励'}}</span>
        <div class="btn-box" @click.stop="toBridge">
          <Button :class="$lang">{{lang=='en'?'Go now':'立即前往'}}</Button>
        </div>
        <!-- <div class="to-web" @click="toWeb">
          <span>Go to official website</span>
        </div> -->
      </div>
      <div class="content-right">
        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/model-bridge-campaign.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Button from '@/components/Button.vue'
export default {
  data() {
    return {

    }
  },
  created() {

  },
  computed: {
        ...mapState(['lang']),
    },
  components: {
    Button,
  },
  methods: {
    afterClose() {
      console.log('xxxxx')
      this.$emit('closeModel')
    },
    toWeb() {
      this.afterClose()
      this.$emit('closeModel')
    },
    toBridge() {
      this.$router.push({ name: 'AllMedalWall' })
      // window.open('https://bridge.wodrpc.org')
    }
  },
}
</script>
<style lang="scss" scoped>
.model-bridge-campaign {
  width: 90%;
  height: 764px;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 35px;
  border: 2px solid rgba(255, 255, 255, 0.20);
  background: #000;
  padding: 0 88px 0 106px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 965px) and (max-width: 1440px) {
    width: 90%;
    height: 600px;
    padding: 0 40px 0 60px;
  }

  @screen md {
    width: 90%;
    height: auto;
    padding: 39px 25px 35px;
  }

  .close {
    cursor: pointer;
    padding: 12px;
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      width: 100%;
    }
  }

  .bridge-campaign-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 29px;

    @screen md {
      flex-direction: column-reverse;
      gap: 20px;
    }

    .content-left {
      margin-bottom: 56px;
      display: flex;
      flex-direction: column;

      @media (min-width: 965px) and (max-width: 1440px) {
        margin-bottom: 0;
      }

      @screen md {
        margin-bottom: 20px;
        text-align: center;
      }

      h1 {
        color: #fff;
        font-size: 64px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0.64px;

        @media (min-width: 965px) and (max-width: 1440px) {
          font-size: 48px;
          line-height: 56px;
        }

        @screen md {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 0.32px;
          text-align: center;
        }

        span {
          background-image: linear-gradient(to right, #33C4F2, #1F35FD);
          background-clip: text;
          color: transparent;
          display: inline-block;
        }
      }

      .second {
        color: #FFF;
        font-size: 29.223px;
        font-weight: 400;
        display: inline-block;
        margin-bottom: 50px;

        @media (min-width: 965px) and (max-width: 1440px) {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 30px;
        }

        @screen md {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }

      .slogon {
        color: rgba(255, 255, 255, 0.60);
        font-size: 19.475px;
        font-weight: 300;
        line-height: 26.787px;
        display: inline-block;
        margin-bottom: 70px;

        @media (min-width: 965px) and (max-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 30px;
        }

        @screen md {
          font-size: 12px;
          margin-bottom: 20px;
        }
      }

      .btn-box {
        width: 272px;
        display: inline-block;
        margin-bottom: 35px;

        @screen md {
          margin: auto;
          margin-bottom: 20px;
          width: 188px;
        }

        button {
          width: 100%;
        }
      }

      .to-web {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.60);
        font-size: 16px;
        font-weight: 300;
        line-height: 27.576px;
        padding-left: 33px;

        @screen md {
          padding-left: 0;
          font-size: 12px;
        }

        span {
          padding-bottom: 10px;
          display: inline-block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.60);

          @media (min-width: 965px) and (max-width: 1440px) {
            padding-bottom: 0px;
            font-size: 12px;
          }

          @screen md {
            padding-bottom: 4px;
          }
        }
      }
    }

    .content-right {
      width: 555px;
      min-width: 555px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 965px) and (max-width: 1440px) {
        width: 440px;
        min-width: 440px;
      }

      @screen md {
        width: 214px;
        min-width: 214px;
      }

      img {
        width: 100%;
      }
    }
  }
}
</style>