import Vue from 'vue';
import Vuex from 'vuex';
import persistedstate from 'vuex-persistedstate';
import { defaultLang } from '../../config';
import { setAddress, getAddress } from '@/utils/auth.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lang: window.localStorage.getItem('lang') || defaultLang,
        locales: [
            {
                value: 'en',
                text: 'EN',
            },
            {
                value: 'zh',
                text: 'ZH',
            },
        ],
        isConnect: false,
        address: getAddress(), //用户地址
        config: {},
        token: '', // 登录Token
        linkURL: 'https://app.ajlab.xyz/#',
        screenWidth: 0,
        info: {},
        coinConfig: {}, // 币种定义
        balance: {}, // 用户余额列表
        assetParams: {},
        enum:{},
        visible:false,
    },
    getters: {
        currentLang(state) {
            return state.lang;
        },
    },
    mutations: {
        SET_VISIBLE(state,val){
            state.visible = val;
        },
        SET_ENUM(state,val){
            state.enum = val;
        },
        SET_ASSET_PARAMS(state, val) {
            state.assetParams = val;
        },
        SET_BALANCE(state, val) {
            state.balance = val;
        },
        SET_INFO(state, val) {
            state.info = val;
        },
        SET_CONNECT(state, val) {
            state.isConnect = val;
        },
        // 设置屏幕宽度
        SET_SCREENWIDTH(state, val) {
            state.screenWidth = val;
        },
        //清理数据
        CLEAR(state) {
            setAddress('');
            state.address = '';
        },
        SET_TOKEN(state, val) {
            state.token = val;
        },
        SET_LANG(state, data) {
            state.lang = data;
        },
        // 保存网络环境
        SET_NETWORK: (state, network) => {
            state.network = network;
        },
        // 保存地址
        SET_ADDRESS: (state, address) => {
            console.log('address', address);
            state.address = address;
        },
        SET_CONFIG: (state, config) => {
            state.config = config;
        },
        SET_COINCONFIG: (state, val) => {
            state.coinConfig = val;
        },
    },
    actions: {
        setLang({ commit }, payload) {
            commit('SET_LANG', payload);
        },
    },
    plugins: [
        persistedstate({
            storage: window.sessionStorage,
            reducer(val) {
                return val;
            },
        }),
    ],
});
