<template>
    <div @click="clickHandle" class="mbutton" :style="style" :class="[`mbutton__${size}`, `mbutton__${type}`, { mbutton__loading: loading, mbutton__disabled: disabled }]">
        <img v-if="loading" class="mbutton_loading" style="margin-right: 2px" src="@/assets/img/default/btn-spin.png" />
        <span>{{ value || '提交' }}</span>
        <slot class="btn-title"></slot>
    </div>
</template>

<script>
/**
 * 使用：<mbutton :loading="rechargeLoading" @click="handleSubmit()" />
 */
export default {
    name: 'mbutton',
    props: {
        value: {
            type: String,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'primary', //  default（默认） |  primary（次重要） | none关闭背景  | hollow空心 | falls灰色(会强制设置颜色)
        },
        size: {
            type: String,
            default: 'middel', // small | default | small
        },
        boxShadow: {},
        borderColor: {
            type: String,
            default: '$theme-color',
        },
    },
    computed: {
        style() {
            // const mar = this.mar.split(" ").filter(x => x);
            // const pad = this.pad.split(" ").filter(x => x);
            // const radius = this.radius.split(" ").filter(x => x);
            let style = {
                // padding: pad.map(n => px2rem(`${n}px`)).join(' '),
                // margin: mar.map(n => px2rem(`${n}px`)).join(' '),
                margin: '10rpx',
            };
            return style;
        },
    },
    data() {
        return {};
    },
    methods: {
        clickHandle() {
            if (!this.disabled && !this.loading) {
                this.$emit('click');
            } else {
                console.log('操作太快');
            }
        },
    },
};
</script>

<style lang="scss">
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mbutton {
    padding: 4px 40px;
    // @extend %landscape-gradient;
    border: 1px solid #0421df;
    background: #0421df;
    // box-shadow: 0px 5px 9px 0px rgba(35, 97, 254, 0.4);
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;

    &__default {
        background: #0421df;
        color: #fff;
    }

    &__hollow {
        border: 1px solid #0421df;
        background: unset;
        white-space: nowrap;
        color: #0421df;
    }

    &__small {
        height: 60px;
        padding: 0 16px;
    }

    &__warn {
        background: #e4ac28;
    }

    &__disabled {
        -webkit-filter: grayscale(95%);
        filter: grayscale(95%);
    }

    &__loading {
        filter: grayscale(0.5);
        cursor: not-allowed;
    }

    &_loading {
        width: 32px;
        height: 32px;
        padding: 4px;
        animation: rotate 1s infinite linear;
    }
}
</style>
