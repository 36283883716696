<template>
    <div class="model-bridge-campaign">
        <div class="bridge-campaign-content">
            <img class="topImg" src="../../assets/img/login/noLogin.png" alt="" />
            <p class="title">{{lang=='en'?"Haven't logged in yet, are you logged in?":'还没登录，是否登录？'}}</p>
            <div class="itemOne" style="width: 100%">
                <p>
                    <img src="../../assets/img/login/address.png" alt="" />
                    <span>{{lang=='en'?"Current address":'当前地址'}}</span>
                </p>
                <p>
                    <input type="text" disabled v-model="address" placeholder="" />
                </p>
            </div>
            <Mbutton style="width: 100%" @click="submitFn" :value="lang=='en'?'Sign In':'登录'" :loading="loading" />
        </div>
    </div>
</template>
<script>
import Mbutton from '@/components/Mbutton.vue';
import { mapState } from 'vuex';
export default {
    name: 'ModelLogin',
    props: {
        value: {
            default: '',
            type: String,
        },
        loading: {
            default: false,
            type: Boolean,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['address','lang']),
        // ...mapState({
        //     address: state => state.address,
        // }),
    },
    created() {},
    components: {
        Mbutton,
    },
    methods: {
        afterClose() {
            this.$emit('closeModel');
        },
        submitFn() {
            this.$emit('submitEvent');
        },
        // toWeb() {
        //   this.afterClose()
        //   this.$emit('closeModel')
        // },
    },
};
</script>
<style lang="scss" scoped>
.model-bridge-campaign {
    width: 800px;
    height: 764px;
    background-color: #0e0e0e !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 35px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: #000;
    padding: 0 88px 0 106px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 965px) and (max-width: 1440px) {
        width: 800px;
        height: 700px;
        padding: 0 40px 0 60px;
    }

    @screen md {
        width: 90%;
        height: auto;
        padding: 39px 25px 35px;
    }

    .close {
        cursor: pointer;
        padding: 12px;
        position: absolute;
        top: 16px;
        right: 16px;

        img {
            width: 100%;
        }
    }

    .bridge-campaign-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px 29px;
        color: #fff;
        .topImg {
            width: 40%;
        }
        .title {
            color: #fff;
            font-size: 16px;
        }
        .itemOne {
            display: flex;
            flex-direction: column;
            p:nth-of-type(1) {
                margin-bottom: 8px !important;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
                span {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
            & > p:nth-of-type(2) {
                width: 100%;
                background: #0e0e0e;
                border-radius: 75px;
                border: 1px solid #0421DF;
                padding: 16px;
                border-radius: 8px;
                input {
                    background: unset;
                    width: 100%;
                }
            }
        }
        .itemOne2 {
            display: flex;
            flex-direction: column;
            p:nth-of-type(1) {
                margin-bottom: 8px !important;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
                span {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
            & > p:nth-of-type(2) {
                width: 100%;
                background-color: #29292d;
                padding: 16px;
                border-radius: 8px;
                input {
                    background: unset;
                    width: 100%;
                    outline: unset;
                }
            }
        }
    }
}
</style>
