<template>
  <a-modal centered :visible="visible" :closable="false" :maskClosable="true" @cancel="afterClose" :footer="null"
    wrapClassName="blur-modal" :maskStyle="{ backdropFilter: 'blur(16px)' }">
    <div class="prompt">
      <div class="close md:w-24 md:p-0" @click="afterClose">
        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/close.svg" alt="">
      </div>
      <div class="icon-box">
        <img v-show="promptObj.status === 1" src="https://s3.us-west-1.amazonaws.com/agi.love/img/success-icon.svg" alt="">
        <img v-show="promptObj.status === 2" src="https://s3.us-west-1.amazonaws.com/agi.love/img/fail-icon.svg" alt="">
      </div>
      <span class="content" v-if="promptObj.status === 1">{{ promptObj.content }}</span>
      <span class="content" v-if="promptObj.status === 2">{{ promptObj.errMsg }}</span>
      <span class="btn" v-if="promptObj.route" @click="toPage">{{ promptObj.button }}</span>
      <span class="btn" v-if="!promptObj.route" @click="afterClose">OK</span>
    </div>
  </a-modal>
</template>
<script>
import router from '../router'
export default {
  name: 'prompt',
  props: {
    prompt: {
      type: Object,
      default: () => {
        return {
          status: 1,
          content: '',
          errMsg: '',
          button: '',
          route: '',
        }
      }
    },
  },
  data() {
    return {
      visible: false,
      promptObj: {
        status: 1,
        content: '',
        errMsg: '',
        button: '',
        route: ''
      }
    }
  },
  created() {

  },
  methods: {
    // 打开弹窗
    showPrompt() {
      this.promptObj = this.prompt
      this.visible = true
    },
    afterClose() {
      // 关闭初始化
      this.visible = false
      // this.promptObj = {
      //   status: 1,
      //   content: '',
      //   errMsg: '',
      //   button: '',
      //   route: ''
      // }
    },
    toPage() {
      if (!this.promptObj.route) return
      this.afterClose()
      router.push({ path: this.promptObj.route })
    }
  },
}
</script>
<style lang="scss">
.blur-modal {
  .ant-modal {
    width: auto !important;
    max-width: none !important;

    .ant-modal-content {
      color: #fff;
      background-color: transparent;

      .ant-modal-body {
        padding: 0;

        .prompt {
          position: relative;
          width: 480px;
          height: 269px;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.20);
          background: #1A1A1D;
          backdrop-filter: blur(20px);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 32px;

          @screen md {
            width: 337px;
          }

          .close {
            cursor: pointer;
            padding: 12px;
            position: absolute;
            top: 16px;
            right: 16px;

            img {
              width: 100%;
            }
          }

          .content {
            color: rgba(255, 255, 255, 0.80);
            font-size: 20px;
            font-weight: 590;
            line-height: 20px;
            /* 100% */
          }

          .btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            padding: 14px 32px;
            border-radius: 12px;
            color: #FFF;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            /* 100% */
            letter-spacing: 0.16px;
            background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
          }
        }
      }
    }
  }
}</style>