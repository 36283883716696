const ChainCfg = {
    998899: {
        chainId: '',
        chainName: 'BSCTest', //
        nativeCurrency: {
            name: 'BSCTest',
            symbol: 'BSCT',
            decimals: 18,
        },
        rpcUrls: ['http://45.207.47.32:27450'],
    },
    99887755: {
        chainId: '0x5f42a8b',
        chainName: '国潮链',
        nativeCurrency: {
            name: 'GCC',
            symbol: 'GCC',
            decimals: 18,
        },
        rpcUrls: ['https://node.zx6868.com'],
    },
    1: {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    3: {
        chainId: '0x3',
        chainName: 'Ropsten testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://ropsten.etherscan.io'],
    },
    42: {
        chainId: '0x2a',
        chainName: 'Kovan testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://kovan.etherscan.io'],
    },
    56: {
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    },
    97: {
        chainId: '0x61',
        chainName: 'Binance Smart Chain - TestNet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
    1088: {
        chainId: '0x440',
        chainName: 'Maas - TestNet',
        nativeCurrency: {
            name: 'Maas',
            symbol: 'Maas',
            decimals: 18,
        },
        rpcUrls: ['https://maas-test-node.onchain.com/'],
        blockExplorerUrls: ['https://maas-test-explorer.onchain.com/'],
    },
    2088: {
        chainId: '0x828',
        chainName: 'Maas',
        nativeCurrency: {
            name: 'Maas',
            symbol: 'Maas',
            decimals: 18,
        },
        rpcUrls: ['https://maas-node.onchain.com/'],
        blockExplorerUrls: ['https://maas-explorer.onchain.com/'],
    },
    737373: {
        chainId: '0xb405d', // 网络id，16进制的字符串
        chainName: 'WOD-TESTNET', // 添加到钱包后显示的网络名称
        rpcUrls: [
            'https://chain.wod.ai', // rpc地址
        ],
        iconUrls: [
            'https://miner.wod.ai/logo', // 网络的图标，暂时没看到在哪里会显示
        ],
        blockExplorerUrls: [
            'https://explorer.wod.ai', // 网络对应的区块浏览器
        ],
        nativeCurrency: {
            // 网络主币的信 息
            name: 'WOD',
            symbol: 'WOD',
            decimals: 18,
        },
    },
};

export default ChainCfg;
