<template>
    <div class="model-bridge-campaign">
        <div class="close md:w-24 md:p-0" @click.stop="afterClose">
            <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/close.svg" alt="" />
        </div>
        <div class="bridge-campaign-content">
          <img class="topImg" src="../../assets/img/login/topBg.png" alt="">
          <p class="title">{{lang=='en'?"If you haven't activated your account yet, activate your account first":'还没激活账号，先激活账号'}}</p>
          <div class="itemOne"> 
            <p>
              <img src="../../assets/img/login/address.png" alt="">
              <span>{{lang=='en'?"Current address":'当前地址'}}</span>
            </p>
            <p>
              <input type="text" disabled v-model="address" placeholder="" />
            </p>
          </div>
          <div class="itemOne2"> 
            <p>
              <img src="../../assets/img/login/inv.png" alt="">
              <span>{{lang=='en'?"Invitation address":'邀请地址'}}</span>
            </p>
            <p>
                <input type="text" :disabled="disabled" :placeholder="lang=='en'?'Please enter an address':'请输入地址'" :value="value" @input="$emit('input', $event.target.value)" />
            </p>
          </div>
          <Mbutton @click="submitFn" :loading="loading" />
        </div>
    </div>
</template>
<script>
import Mbutton from '@/components/Mbutton.vue';
import { mapState } from 'vuex';
export default {
    name: 'ModelSign',
    props: {
      value: {
            default: '',
            type: String,
        },
        loading:{
            default:false,
            type:Boolean
        },
        disabled:{
          default:false,
            type:Boolean
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['address','lang']),
        // ...mapState({
        //     address: state => state.address,
        // }),
    },
    created() {},
    components: {
        Mbutton,
    },
    methods: {
        afterClose() {
            this.$emit('closeModel');
        },
        submitFn(){
            this.$emit('submitEvent');
        }
        // toWeb() {
        //   this.afterClose()
        //   this.$emit('closeModel')
        // },
    },
};
</script>
<style lang="scss" scoped>
.model-bridge-campaign {
    width: 800px;
    height: 764px;
    background-color: #0E0E0E !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 35px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: #000;
    padding: 0 88px 0 106px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 965px) and (max-width: 1440px) {
        width: 800px;
        height: 700px;
        padding: 0 40px 0 60px;
    }

    @screen md {
        width: 90%;
        height: auto;
        padding: 39px 25px 35px;
    }

    .close {
        cursor: pointer;
        padding: 12px;
        position: absolute;
        top: 16px;
        right: 16px;

        img {
            width: 100%;
        }
    }

    .bridge-campaign-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px 29px;
        color: #fff;
        .topImg{
          width: 60%;
        }
        .title{
          color: #fff;
          font-size: 16px;
        }
        .itemOne{
          display: flex;
          flex-direction: column;
          p:nth-of-type(1){
          margin-bottom: 8px !important;
            display: flex;
            align-items: center;
            img{
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span{
                font-size: 15px;
                font-weight: 600;
            }
          }
          &>p:nth-of-type(2){
            width: 100%;
            background-color: #0421DF;
            padding: 16px;
            border-radius: 8px;
            input{
              background:unset;
              width: 100%;
            }
          }
        }
        .itemOne2{
          display: flex;
          flex-direction: column;
          p:nth-of-type(1){
          margin-bottom: 8px !important;
            display: flex;
            align-items: center;
            img{
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span{
                font-size: 15px;
                font-weight: 600;
            }
          }
          &>p:nth-of-type(2){
            width: 100%;
            background-color: #29292D;
            padding: 16px;
            border-radius: 8px;
            input{
              background:unset;
              width: 100%;
              outline: unset
            }
          }
        }
    }
}
</style>
