<template>
  <div class="c-dapp md:px-12">
    <img class="static-bg-2" src="https://s3.us-west-1.amazonaws.com/agi.love/img/static-bg-2.webp" alt="">
    <div class="content">


      <!-- <div class="header md:pt-28">
        <div class="text font-bold md:pl-22 md:text-20">联合学习</div>
        <div>
          <div class="desc md:ml-22 md:text-12 md:mt-8 md:pr-20">
            通过联合学习算法，无论你身在何处，计算能力始终可以稳定地为你提供服务。
          </div>
        </div>
      </div> -->
      <img class="dapp-circle" src="https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-circle.webp" alt="">
      <div class="why-work">
        <div class="question"><span class="dot"></span>{{lang=='en'?'Founding Team':'创始团队'}}</div>
        <div class="low-coding md:text-24 md:leading-32">
          <Language :text="lang=='en'?'Daniel · Lack':'丹尼尔·拉克'">
            <div slot="r1" slot-scope="data">{{ data.value }}</div>
          </Language> 
        </div>
        <div class="description md:text-12 md:leading-18 md:mt-15 md:px-20">{{lang=='en'?'CEO, founder of AJ Labs, and one of the co-founders of Coinbase.':'首席执行官，AJ Labs创始人，Coinbase联合创始人之一。'}}</div>
      </div>
      <div class="solutions md:block">
        <div class="items items-left">
          <div class="title font-bold">{{lang=='en'?'Vitalik · Buterin':'维塔利克·布特林'}}</div>
          <div class="desc">{{lang=='en'?'Chief Technology Officer, Canada programmer, one of the founders of Ethereum.':'首席技术官，加拿大籍程序员，以太坊创始人之一。'}} </div>
        </div>
        <div class="dapp-triangle-img">
          <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-triangle.png" alt="">
        </div>
        <div class="items items-right">
          <div class="title font-bold">{{lang=='en'?'Nils · Grossberg':'尼尔斯·格罗斯伯格'}}</div>
          <div class="desc">{{lang=='en'?'Chief Financial Officer, United States Silicon Valley technology giant, is one of the co-founders of stnear coin.':'首席财务官，美国硅谷技术巨匠，是stnear币的联合创始人之一。'}}</div>

          <img class="hidden md-bg-img-1 md:block" src="https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-triangle.png" alt="">
        </div>
      </div>
      <div class="items md:mt-70" style="text-align: center; margin-top: 47px;">
        <div class="title font-bold md:px-60">{{lang=='en'?'Jason · Andreas':'杰森·安德烈亚斯'}}</div>
        <div class="desc md:px-50">{{lang=='en'?'Chief Marketing Officer, one of the key Bitcoin evangelists. He has deep knowledge in computer science, distributed systems, and cryptography.':'首席营销官，比特币重要布道者之一 。他在计算机科学、分布式系统和密码学方面有深厚造诣。'}}</div>
        <img class="md-bg-img-2 hidden md:block" src="https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-triangle.png" alt="">

      </div>
    </div>
  </div>
</template>

<script>
import Language from '@/components/common/Language'
import { mapState } from 'vuex';
export default {
  components: {
    Language
  },
  computed: {
        ...mapState(['lang']),
    },
}
</script>

<style lang="scss" scoped>
.c-dapp {
  margin: 50px 0;
  position: relative;
  padding: 0 75px;

  .content {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
  }

  .static-bg-2 {
    position: absolute;
    bottom: -200px;
    right: 0;

    @screen md {
      bottom: 0;
    }
  }

  .header {
    height: 156px;
    width: 100%;
    background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-header.png');
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @screen md {
      display: block;
      background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-header-m.png');
      height: 293px;
    }

    div {
      flex: 1;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #A0A3AC;

    }

    .text {
      font-size: 32px;
      line-height: 38px;
      color: white;
      width: 874px;
      padding-left: 57px;

      @screen md {
        width: auto;
      }
    }

    .desc {
      max-width: 480px;
      margin-left: 64px;
    }
  }

  .dapp-circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 823px;
    top: -115px;
    z-index: -1;

    @screen md {
      width: 100%;
      top: 180px;
    }
  }

  .why-work {
    padding-top: 170px;
    @screen md {
      padding-top: 80px;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 6px;
    background-color: #253bff;
    margin-right: 7px;
  }

  .question {
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
  }

  .low-coding {
    font-size: 54px;
    font-weight: bold;
    line-height: 68px;
    text-align: center;
    color: white;
  }

  .description {
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-top: 15px;
  }

  .solutions {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    position: relative;
    overflow: hidden;

    &::before {
      height: 1px;
      display: block;
      content: "";
      background: linear-gradient(90deg, #29292C 50%, rgba(41, 41, 44, 0.4) 89.57%);
      position: absolute;
      bottom: 0;
      width: 688px;
      left: 50%;
      width: 50%;
      z-index: 9;

      @screen md {
        display: none;
      }
    }

    &::after {
      height: 1px;
      display: block;
      content: "";
      position: absolute;
      right: 50%;
      bottom: 0;
      width: 50%;
      background: linear-gradient(270deg, #29292C 60%, rgba(41, 41, 44, 0.4) 89.57%);
      z-index: 9;

      @screen md {
        display: none;
      }
    }

    .dapp-triangle-img {
      position: relative;
      z-index: 10;

      @screen md {
        position: absolute;
        width: 317px;
        left: 50%;
        top: 0;
      }

      &::after {
        background-color: #000000;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: 0;
        content: "";
        display: block;
        z-index: 10;
        height: 1px;

        @screen md {
          background: linear-gradient(270deg, #29292C 60%, rgba(41, 41, 44, 0.4) 89.57%);
          left: -50%;
          right: 100%;
        }
      }
    }

    .items {
      max-width: 440px;
    }
  }

  .items {

    @screen md {

      &.items-left {
        padding-top: 80px;
        width: 70%;
      }

      &.items-right {
        padding-left: 30%;
        margin-top: 220px;
        position: relative;
        padding-bottom: 140px;

        &::after {
          background: linear-gradient(90deg, #29292C 50%, rgba(41, 41, 44, 0.4) 89.57%);
          position: absolute;
          left: 56%;
          right: 1px;
          bottom: 0;
          content: "";
          display: block;
          z-index: 10;
          height: 1px;
        }

        .md-bg-img-1 {
          position: absolute;
          right: 43%;
          bottom: 0;
        }
      }
    }
    .desc {
      font-size: 20px;
      line-height: 28px;
      margin-top: 34px;
      color: rgba(255, 255, 255, 0.6);

      @screen md {
        font-size: 12px!important;
        line-height: 18px!important;
      }

      @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .title {
      font-size: 32px;
      line-height: 42px;
      color: white;

      @screen md {
        font-size: 20px!important;
        line-height: 24px!important;
      }

      @media screen and (max-width: 1440px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .md-bg-img-2 {
      transform: rotateX(180deg);
      margin: 0 auto;
    }
  }
}</style>