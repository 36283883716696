<template>
    <div id="app">
        <router-view v-if="isRouterAlive" />
        <!-- 注册 -->
        <div class="Faucet-entrance-box" v-if="isShowSign">
            <ModelSign :disabled="disabled" :loading="btnLoading" v-model="invcode" @closeModel="onClose" @submitEvent="submitfn"></ModelSign>
        </div>
        <!-- 登录 -->
        <div class="Faucet-entrance-box" v-if="isConnect && address && address != 'undefined' && $route.path != '/' && !isShowSign && !token">
            <ModelLogin :loading="btnLoading" @submitEvent="loginfn" />
        </div>
        <!-- 链ID判断 -->
        <div class="Faucet-entrance-box" v-if="$route.path != '/' && !isConnect && isConnectShow">
            <ModelConnect :type="type" :loading="btnLoading" @submitEvent="submitEventFn" />
        </div>
    </div>
</template>

<script>
import { enable, disconnect, registerListener, getSign, getAndsetAddress, eth_chainId } from '@/web3utils/base/index.js';
import { changeNetwork } from '@/web3utils/utils/ChangeNet.js';
import { setAddress, getAddress } from '@/utils/auth.js';
import { GetConfig, coinlist, GetEnum } from '@/api/Ctrls.js';
import { GetKey, CheckAddressRegisted, Register_address, UserLoginAddress, Info } from '@/api/Users.js';
import { mapState } from 'vuex';
import ModelSign from './components/openSign/ModelSign.vue';
import ModelLogin from './components/openLogin/ModelLogin.vue';
import ModelConnect from './components/openConnect/ModelConnect.vue';
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isShowSign: false,
            signData: '', // 签名信息
            invcode: '',
            btnLoading: false,
            disabled: false,
            screenWidth: 0,
            isRouterAlive: true,
            isConnectShow: false,
            type: 1,
            needChainID: 56, // 998899   56
        };
    },
    components: {
        ModelSign,
        ModelLogin,
        ModelConnect,
    },
    computed: {
        ...mapState({
            address: state => state.address,
            token: state => state.token,
            isConnect: state => state.isConnect,
        }),
    },
    watch: {
        $route(to, from) {
            console.log('Route changed');
            this.$store.commit('SET_VISIBLE', false);
            if (this.path !== '/' && (!this.address || this.address == 'undefined')) {
                console.log('未登录');
                this.$store.commit('SET_CONNECT', false);
                this.isConnectShow = true;
                return;
            }
            if (this.address == 'undefined') return;
            if (to.path == '/' || !this.address) return (this.isShowSign = false);
            this.checkLoginFn();
        },
        '$route.query': {
            handler(newQuery, oldQuery) {
                console.log('newQuery', newQuery);
                if (newQuery.address) {
                    this.disabled = true;
                    this.invcode = newQuery.address;
                }
            },
            deep: true, // 深度监听，确保能捕获到嵌套对象的变化
        },
    },
    async mounted() {
        this.$store.commit('SET_ASSET_PARAMS', { type: 1 });
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        await getAndsetAddress();
        console.log('getAddress()', getAddress());
        console.log('this.address', this.address);
        if (this.address != getAddress()) {
            // TP地址已切换
            this.$store.commit('SET_TOKEN', '');
            this.$store.commit('SET_ADDRESS', getAddress());
        }
        GetConfig();
        coinlist();
        GetEnum();
        this.checkChain();
        registerListener();
    },
    methods: {
        async submitEventFn() {
            if (this.type == 1) {
                //获取登录
                let enables = await enable();
                if (!enables.code) {
                    this.$message('钱包未登录');
                    return;
                }
                this.$store.commit('SET_CONNECT', true);
            } else {
                this.btnLoading = true;
                let res = await changeNetwork(this.needChainID);
                this.checkChain();
                this.btnLoading = false;
            }
        },
        async checkChain() {
            let { code, msg, chainId } = await eth_chainId({
                chainIdArr: [this.needChainID], // 允许的chainID
            });
            if (!code) {
                if (this.address) this.type = 2;
                this.isConnectShow = true;
                this.$store.commit('SET_CONNECT', false);
            } else {
                this.isConnectShow = false;
                this.$store.commit('SET_CONNECT', true);
            }
        },
        async reload() {
            // this.$store.commit('common/ADD_LOADING');
            await this.$sleep(1);
            // this.$store.commit('common/CLOSE_LOADING');
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
            this.$store.commit('SET_SCREENWIDTH', this.screenWidth);
        },
        async submitfn() {
            if (!this.invcode) return this.$message.error('Please enter the invitation code');
            this.btnLoading = true;
            let res = await CheckAddressRegisted({ address: this.invcode });
            if (!res.success) {
                this.btnLoading = false;
                return this.$message.error('该邀请地址无效');
            }
            this.signData = (await GetKey({ address: this.address })).data;
            let signMsg = await getSign(this.signData);
            if (!signMsg) return (this.btnLoading = false);
            let res2 = await Register_address({
                signData: signMsg,
                address: this.address,
                invcode: this.invcode,
            });
            if (res2.success) {
                this.$message.success(res2.msg);
                this.$store.commit('SET_TOKEN', res2.data);
                // window.location.reload();
                this.btnLoading =false;
                this.isShowSign=false;
                this.reload();
               
            } else {
                this.$message.error(res2.msg);
            }
        },
        // 检查是否注册
        async checkLoginFn() {
            if (this.token) return;
            let res = await CheckAddressRegisted({ address: this.address });
            if (!res.success) return (this.isShowSign = true);
        },
        // 登录
        async loginfn() {
            this.btnLoading = true;
            this.signData = (await GetKey({ address: this.address })).data;
            let signMsg = await getSign(this.signData);
            if (!signMsg) this.btnLoading = false;
            let res2 = await UserLoginAddress({
                signData: signMsg,
                address: this.address,
            });
            this.btnLoading = false;
            if (res2.success) {
                // this.$store.commit('SET_TOKEN', res2.data);
                // window.location.reload();
                this.reload();
            } else {
                this.$message.error(res2.msg);
            }
        },
        onClose() {
            this.isShowSign = false;
            this.$router.push('/');
        },
        // preventDefault(e) {
        //     e.preventDefault();
        // },
        // disableScroll() {
        //     document.body.addEventListener('wheel', this.preventDefault, { passive: false });
        //     document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
        // },
        // enableScroll() {
        //     document.body.removeEventListener('wheel', this.preventDefault);
        //     document.body.removeEventListener('touchmove', this.preventDefault);
        // },
    },
};
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
}

html,
body {
    background-color: #000000 !important;

    .h-w-6 {
        width: 6px !important;
        height: 6px !important;
    }
}
#app {
    & > div:nth-of-type(1) {
        background: url('https://s3.us-west-1.amazonaws.com/agi.love/img/bg-1.webp') no-repeat;
        background-position: 0px -99px;
        background-size: 975px 974px;
        z-index: 2;
    }
}
.signBox {
    position: relative;
    z-index: 2;
}
.Faucet-entrance-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: rgba(1, 1, 1, 0.8) !important;
}
.icon{
    margin-left: 4px;
    width: 20px !important;
    height: 20px !important;
}
.icon2{
    opacity: 0.6;
}
.fs16{
    font-size: 16px !important;
}
.fs15{
    font-size: 15px !important;
}
.fs14{
    font-size: 14px !important;
}
.fs13{
    font-size: 13px !important;
}
.fs12{
    font-size: 12px !important;
}
.model_01 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  transition: opacity .5s;
  z-index: 2008;
}
</style>
